import React, {useState, useEffect} from "react";

import { Row, Col, Divider, Form, Input, Button } from "antd";

export default function PasswordProfile() {
  const [token, setToken] = useState(localStorage.getItem("beetUserToken"));
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [newpassword2, setNewPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [changedPass, setChangedPass] = useState(null)
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    if (errorMessage === false || errorMessage === true) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
    
  }, [errorMessage]);

  useEffect(() => {
    if (changedPass === false || changedPass === true) {
      setTimeout(() => {
        setChangedPass(null);
      }, 3000);
    }
    
  }, [changedPass]);

  const submitPasswordChange = async () => {

    async function passchange(email) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: JSON.stringify(
          `grant_type=&username=${email}&password=${oldpassword}&scope=&client_id=${newpassword}&client_secret=`
        ),
      };
  
      const response = await fetch(`${apiUrl}/api/changepass`, requestOptions);
      const data = await response.json();
      console.log(data.detail)
      setChangedPass(data.detail)

}
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`${apiUrl}/api/users/me`, requestOptions);
    const data = await response.json();
    passchange(data.email)
  };

  

  const handleSubmit = async () => {
    if (newpassword === newpassword2 && newpassword.length > 5) {
      submitPasswordChange();
    } else {
      setErrorMessage(true);

    }
    form.resetFields();
  };
  const [form] = Form.useForm();
  return (
    <Row>
      <Col span={24}>
        <h2>Change Password</h2>
        <p className="hp-p1-body hp-mb-0">
          Set a unique password to protect your account.
         </p>

        <Divider className={dividerClass} />
      </Col>

      <Col xxl={15} xl={15} md={15} span={24}>
        <Form layout="vertical" name="basic" form={form} onFinish={handleSubmit}>
          <Form.Item
            label={
              <span className="hp-input-label hp-text-color-black-100 hp-text-color-dark-0">
                Old Password :
              </span>
            }
            name="old-password" value={oldpassword}
          >
            <Input.Password value={oldpassword} placeholder="Enter your old password" onChange={(e) => setOldPassword(e.target.value)}/>
          </Form.Item>

          <Form.Item
            label={
              <span className="hp-input-label hp-text-color-black-100 hp-text-color-dark-0">
                New Password :
              </span>
            }
            name="new-password" value={newpassword}
          >
            <Input.Password value={newpassword} placeholder="Enter your new password" onChange={(e) => setNewPassword(e.target.value)}/>
          </Form.Item>

          <Form.Item
            label={
              <span className="hp-input-label hp-text-color-black-100 hp-text-color-dark-0">
                Confirm New Password :
              </span>
            }
            name="confirm-new-password" value={newpassword2}
          >
            <Input.Password value={newpassword2} placeholder="Repeat your new password" onChange={(e) => setNewPassword2(e.target.value)}/>
          </Form.Item>
          { changedPass == false && <p style={{ color: "red" }}>Password was not changed</p> }
          { errorMessage == true && <p style={{ color: "red" }}>Ensure that the passwords match and greater than 5 characters</p> }
          { changedPass == true && <p style={{ color: "green" }}>Password was changed successfully</p> }
          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}