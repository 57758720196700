import React, { useState, useEffect, useContext } from "react";
import mammoth from 'mammoth';

import { Radio, Space, Select, Spin } from "antd";

import { Card, Row, Col, Upload, message, Progress, Empty, Button, Tooltip } from "antd";

import CreditMasterCard from "./creditMasterCard";
import OrdersFeatureCard from "./ordersFeatureCard";
import PageContent from "../../../layout/components/content/page-content";
import { UserContext } from "../../../context/UserContext";
import {
  RiUploadCloud2Line,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
} from "react-icons/ri";
const { Dragger } = Upload;;


export default function Neuneutext({balance, onUpdatedBalance, activated}) {

  const [allData, setAllData] = useState([]);
  const [languageInSwitch, setlanguageInSwitch] = useState('Auto');
  const [languageOutSwitch, setlanguageOutSwitch] = useState('EN-GB');
  const [token] = useContext(UserContext);
  const [finduration, setFinduration] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  const [activatedLoaded, setActivatedLoaded] = useState(false);
  const [displayCount, setDisplayCount] = useState(3);
  const apiUrl = process.env.REACT_APP_API_URL;
  const loadMore = () => {
    setDisplayCount(displayCount + 3);
  };


  const onLanguageInChange = (e) => {
    setlanguageInSwitch(e)

  };
  const onLanguageOutChange = (e) => {
    setlanguageOutSwitch(e)

  };
  const getLeads = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/translate`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      const data = await response.json();
      setAllData(data);
      setLoaded(true);
    }
  };

  const getRedisValue = async (filename, service) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    };
    const response = await fetch(`${apiUrl}/getRedisValue?filename=${filename}&service=${service}`, requestOptions);
    if (!response.ok) {
        setErrorMessage("Something went wrong. Couldn't get the Redis value");
    } else {
        const data = await response.json();
        if (data.value === "True") {
            // If the Redis value is "True", reload the leads
            getLeads();
        } else {
            // If the Redis value is not "True", re-trigger this function after 5 seconds
            setTimeout(() => getRedisValue(filename, service), 5000);
        }
    }
};
  const checkDuration = async (duration) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/duration/${duration}_translate`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      const data = await response.json();
      if (data == true) {return true}
      else {return false}
    }
  };
  const withdrawBalance = async (duration) => {
 
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/withdraw/${duration}_translate`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      
      const data = await response.json();
      if (data == true) {return true;}
      else {return false}
    }
  };

  useEffect(() => {
    getLeads();
  }, []);
  useEffect(() => {
    if (balance !== undefined && balance !== null) {
      console.log(balance)
      setBalanceLoaded(true);
    }
  }, [balance]);

  useEffect(() => {
    if (activated !== undefined && activated !== null && activated === true && balance > 1) {
      setActivatedLoaded(true);
    }
    else {setActivatedLoaded(false);}
  }, [activated]);


  const props = {
    name: "file",
    multiple: false,

    action: `${apiUrl}/translate/${languageInSwitch}_${languageOutSwitch}`,
    beforeUpload: async (info) => {
      // Check for file size
      if (info.size > 1000000) {
        message.error({
          content: `${info.name} File is too big. File need to be less then 1MB`,
          icon: <RiCloseCircleLine className="remix-icon" />,
        });
        return false;
      }
    
      const isTxt = info.name.endsWith(".txt");
      const isSrt = info.name.endsWith(".srt");
      const isDocx = info.name.endsWith(".docx");
      
      // Check if the file is txt, srt, or docx
      if (!isTxt && !isSrt && !isDocx) {
        message.error({
          content: `${info.name} file upload failed. File has the wrong format`,
          icon: <RiCloseCircleLine className="remix-icon" />,
        });
        return false;
      }
      
      let dur;
    
      const countSymbols = async (file) => {
        return new Promise((resolve, reject) => { // Added reject for error handling
          const reader = new FileReader();
          reader.onload = async (event) => { // Made it async for DOCX processing
            if (isTxt) {
              const content = event.target.result.replace(/\s+/g, '');
              resolve(content.length);
            } else if (isSrt) {
              const lines = event.target.result.split("\n");
              const filteredLines = lines.filter(
                (line) =>
                  !line.match(/^\d+$/) &&
                  !line.match(/^\d{2}:\d{2}:\d{2},\d{3} --> \d{2}:\d{2}:\d{2},\d{3}$/)
              );
              const content = filteredLines.join("").replace(/\s+/g, '');
              resolve(content.length);
            } else if (isDocx) { // Added this block for DOCX
              const arrayBuffer = event.target.result;
              try {
                const result = await mammoth.extractRawText({ arrayBuffer: arrayBuffer });
                const content = result.value.replace(/\s+/g, '');
                resolve(content.length);
                console.log(content.length)
              } catch (err) {
                reject(err); // Reject the promise on error
              }
            }
          };
      
          if (isDocx) {
            reader.readAsArrayBuffer(file); // For DOCX files
          } else {
            reader.readAsText(file); // For TXT and SRT files
          }
        });
      };
    
      dur = await countSymbols(info);
    
      // Check if duration is allowed
      const isDurationAllowed = await checkDuration(dur);
      if (isDurationAllowed) {
        setFinduration(dur);
        return true;
      } else {
        message.error({
          content: `${info.name} file upload failed. You have no enough credits`,
          icon: <RiCloseCircleLine className="remix-icon" />,
        });
        return false;
      }
    },
    maxCount: 1,
    data: { duration: finduration },
    headers: {
      authorization: "Bearer " + token,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success({
          content: `${info.file.name} file uploaded successfully.`,
          icon: <RiCheckboxCircleLine className="remix-icon" />,
        });
        withdrawBalance(finduration);
        onUpdatedBalance();
        setTimeout(() => {
          getLeads();
        }, 2000);
      } else if (status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          icon: <RiCloseCircleLine className="remix-icon" />,
        });
      }

    },
    onDrop() {},
  };
    return (
    <>
    <Row gutter={[32, 32]} className="hp-mb-32">
        <Col span={24}>
        <PageContent
          title="NeuNeu.text"
          desc="NeuNeu.text is translator powered with AI."
          backcolor="#7a54a4"
        />
      </Col>
      <Col align="middle"  span={24}>
        <Col xl={12} md={12} sm={24} span={24}>
        {balanceLoaded ? (
            <OrdersFeatureCard minutes={Math.round(balance/0.00010)} price="($0.10 for 1000 symbols)"/>) : (
              <Spin size="large" />
            )}
          </Col>
            
      </Col>
      <Col align="middle" md={24} sm={24} xs={24}>
        <h4  className="hp-mb-8">Choose input language</h4>
        <p className="hp-p1-body hp-mb-32">
          You can choose your input language for translation. Or just let our algorythms auto-detect language(s)
        </p>
        <Select onChange={onLanguageInChange} defaultValue="Auto">
            <Option value="Auto">Auto-detect</Option>
            <Option value="BG">Bulgarian</Option>
            <Option value="CS">Czech</Option>
            <Option value="DA">Danish</Option>
            <Option value="DE">German</Option>
            <Option value="EL">Greek</Option>
            <Option value="EN">English</Option>
            <Option value="ES">Spanish</Option>
            <Option value="ET">Estonian</Option>
            <Option value="FI">Finnish</Option>
            <Option value="FR">French</Option>
            <Option value="HU">Hungarian</Option>
            <Option value="ID">Indonesian</Option>
            <Option value="IT">Italian</Option>
            <Option value="JA">Japanese</Option>
            <Option value="KO">Korean</Option>
            <Option value="LT">Lithuanian</Option>
            <Option value="LV">Latvian</Option>
            <Option value="NB">Norwegian</Option>
            <Option value="NL">Dutch</Option>
            <Option value="PL">Polish</Option>
            <Option value="PT">Portuguese</Option>
            <Option value="RO">Romanian</Option>
            <Option value="RU">Russian</Option>
            <Option value="SK">Slovak</Option>
            <Option value="SL">Slovenian</Option>
            <Option value="SV">Swedish</Option>
            <Option value="TR">Turkish</Option>
            <Option value="UK">Ukrainian</Option>
            <Option value="ZH">Chinese</Option>

          </Select>
      </Col>
      <Col align="middle" md={24} sm={24} xs={24}>
        <h4  className="hp-mb-8">Choose result language</h4>
        <p className="hp-p1-body hp-mb-32">
          You need to choose your desired result language. English (British) is default output language.
        </p>
        <Select onChange={onLanguageOutChange} defaultValue="EN-GB">
          <Option value="BG">Bulgarian</Option>
          <Option value="CS">Czech</Option>
          <Option value="DA">Danish</Option>
          <Option value="DE">German</Option>
          <Option value="EL">Greek</Option>
          <Option value="EN-GB">English (British)</Option>
          <Option value="EN-US">English (American)</Option>
          <Option value="ES">Spanish</Option>
          <Option value="ET">Estonian</Option>
          <Option value="FI">Finnish</Option>
          <Option value="FR">French</Option>
          <Option value="HU">Hungarian</Option>
          <Option value="ID">Indonesian</Option>
          <Option value="IT">Italian</Option>
          <Option value="JA">Japanese</Option>
          <Option value="KO">Korean</Option>
          <Option value="LT">Lithuanian</Option>
          <Option value="LV">Latvian</Option>
          <Option value="NB">Norwegian</Option>
          <Option value="NL">Dutch</Option>
          <Option value="PL">Polish</Option>
          <Option value="PT-BR">Portuguese (Brazilian)</Option>
          <Option value="PT-PT">Portuguese (European)</Option>
          <Option value="RO">Romanian</Option>
          <Option value="RU">Russian</Option>
          <Option value="SK">Slovak</Option>
          <Option value="SL">Slovenian</Option>
          <Option value="SV">Swedish</Option>
          <Option value="TR">Turkish</Option>
          <Option value="UK">Ukrainian</Option>
          <Option value="ZH">Chinese (simplified)</Option>
          
          </Select>
      </Col>
      <Col align="middle" md={24} sm={24} xs={24}>
      <Space direction="vertical">
      
    <Col align="middle" md={24} sm={24} xs={24}>
    
    </Col>
    </Space>
    </Col>
      <Col align="middle" md={24} sm={24} xs={24}>
      <Card className="hp-border-color-black-40">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row>
            <Col lg={24} span={24}>
              <h4>Upload file</h4>
              <p className="hp-p1-body">
              You can upload any sound file, that contain audio in it. This file will be processed immidiately after the upload. You will get source file and processed file under the next tab on the page.
              </p>
            </Col>

            
          </Row>
        </Col>

        <Col span={24} className="hp-upload-dragger-col">
        {activatedLoaded ? ( 
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <RiUploadCloud2Line className="remix-icon" />
            </p>

            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>

           
          </Dragger> ) : (
          <Tooltip title="You must have more than 1 USD on your balance to use this service">
          <div >
            <Dragger disabled {...props}>
              <p className="ant-upload-drag-icon">
                <RiUploadCloud2Line className="remix-icon" />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
          </div>
        </Tooltip>
            )}
        </Col>
      </Row>


    </Card>
      </Col>
      
    <Col align="middle" md={24} sm={24} xs={24}>
        <h2 className="hp-mb-8">Your files</h2>

      </Col>
      {allData.length == 0 ? (
        <>
        <Col md={12} span={24}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
        <Col md={12} span={24}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
        </>
      ):( <>
        {allData.slice(0, displayCount).map((files) =>
          { 
            let s3out;
            console.log(s3out)
            let src, src2;
            try {
            // Try to parse the JSON
            s3out = JSON.parse(files.s3out);
            console.log(s3out)
            // If JSON is parsed successfully, assign the properties
            src = s3out.file1;
            src2 = s3out.file2;
            } catch (e) {
            // If an error occurs, use files.s3out as is
            console.log('1')
            }
            const name = files.file;
            const service = files.service; // I'm assuming here you have a service property in files
            if (files.s3out === "0") {
            // Here's where you call your getRedisValue function
            getRedisValue(name, service);

        }
            return (
              <>
          {files.s3out === "0" ? <>
            <Col span={24}> <h5 style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>{name}</h5>
    <p className="hp-mb-0 hp-p1-body">File is processing</p> <Spin size="large" />
    <Progress showInfo={false} percent={100} status="active" />  </Col></>
          :
          <Col md={24} span={24}>
          <CreditMasterCard src={src} src2={src2} name={name}/>
        </Col>} 
        </>
        )
      })}
      {displayCount < allData.length && (
          <Col align="middle" span={24}>
          <Button block onClick={loadMore}>Load More</Button>
        </Col>
        )}
      </>
      )}
      
  </Row>
  </>
  )}