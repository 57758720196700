import React, { useState } from "react";

import { Card } from "antd";
import AboutImg from "../../../assets/images/about/aboutabout.jpg"
import MediakitImg from "../../../assets/images/about/aboutmediakit.jpg"
import TermsImg from "../../../assets/images/about/aboutterms.jpg"
import PrivacyImg from "../../../assets/images/about/aboutprivacy.jpg"
import PublicImg from "../../../assets/images/about/aboutoffer.jpg"
const { Meta } = Card;


export default function Aboutout(par) {

    console.log(par.par)
    const [noTitleKey, setNoTitleKey] = useState(par.par);

    function About() {
    
        return (
          <Card
          hoverable
          cover={
            <img
              alt="example"
              src={AboutImg}
              height={170}
              className="hp-img-cover"
            />
          }
        >
          


  <p className="hp-p1-body">An <strong>innovative</strong> technology company that specializes in <strong>harnessing the power of neural networks</strong>. Founded in 2020, our goal is to <strong>revolutionize</strong> the way businesses interact with their customers by utilizing the latest advancements in <strong>artificial intelligence</strong> and <strong>machine learning</strong>.</p>
  <h2>Our Services</h2>
  <p className="hp-p1-body">Our team of experts in AI and machine learning are dedicated to developing solutions that <strong>improve customer understanding and engagement</strong> for our clients. One of our key focuses is the creation of <strong>advanced neural network-based customer relationship management (CRM) systems</strong>.</p>
  <p className="hp-p1-body">These systems use powerful algorithms to analyze data from various sources, such as social media, website analytics, and customer feedback, to gain a deep understanding of customer behavior and preferences. This enables our clients to create targeted and effective marketing campaigns, as well as <strong>improve customer retention and loyalty</strong>.</p>
  <h3>Additional Offerings</h3>
  <p className="hp-p1-body">In addition to CRM solutions, neuneu.net also offers a variety of other services including the development of <strong>neural network-based chatbots</strong>, <strong>image and voice recognition systems</strong>, and <strong>predictive analytics platforms</strong>.</p>
  <p className="hp-p1-body">These solutions aim to <strong>automate and streamline business processes</strong>, and provide valuable insights into operations and performance.</p>
  <h4>Looking to the Future</h4>
  <p className="hp-p1-body">At neuneu.net, we are constantly exploring new ways to apply the latest technologies to the challenges faced by businesses today. Whether you're looking to <strong>improve customer engagement</strong>, <strong>streamline operations</strong> or gain a <strong>competitive edge</strong>, we are here to help.</p>
  <h5>Get in Touch</h5>NeuNeu
  <p className="hp-p1-body">In conclusion, neuneu.net is a leading technology company that specializes in neural networks. We offer advanced CRM systems, chatbots, image and voice recognition, and predictive analytics to help businesses improve customer engagement and streamline operations. <strong>Contact us today</strong> to learn more about how we can help your business succeed.</p>

        </Card>
        );
      }
    function Mediakit() {
    
        return (
          <Card
          hoverable
          cover={
            <img
              alt="example"
              src={MediakitImg}
              height={170}
              className="hp-img-cover"
            />
          }
        >
                    <p className="hp-p1-body">neuneu.net is proud to introduce our suite of cutting-edge audio services, designed to enhance the quality and clarity of sound for professionals and enthusiasts alike. Our services include <strong>NeuNeu.voice</strong>, <strong>NeuNeu.noise</strong>, <strong>NeuNeu.music</strong> and <strong>NeuNeu.reco</strong>.</p>

          {/* <p className="hp-p1-body">neuneu.net is proud to introduce our suite of cutting-edge audio services, designed to enhance the quality and clarity of sound for professionals and enthusiasts alike. Our services include <strong>NeuNeu.voice</strong>, <strong>NeuNeu.noise</strong>, <strong>NeuNeu.music</strong>, <strong>NeuNeu.reco</strong>, <strong>NeuNeu.key</strong> and <strong>NeuNeu.cut</strong>.</p> */}
  
  <p className="hp-p1-body"> <strong>NeuNeu.voice</strong> is a service that can significantly increase the discretion rate of voice and improve its quality. Using advanced machine learning algorithms, NeuNeu.voice is able to identify and enhance key characteristics of the voice, resulting in a more clear and natural sound.</p>
  
  <p className="hp-p1-body"> <strong>NeuNeu.noise</strong> is a service that removes unwanted noise from audio recordings. Whether it's background noise from a busy street or static from a poor quality recording, NeuNeu.noise is able to effectively eliminate it, leaving you with a cleaner, clearer sound.</p>
  
  <p className="hp-p1-body"> <strong>NeuNeu.music</strong> is a service that can separate a music track into two different tracks: the vocal and the instrumental. This allows you to isolate specific elements of a song, making it easier to remix or create new compositions.</p>
  
  <p className="hp-p1-body"> <strong>NeuNeu.reco</strong> is a service that can help recognize voice into text in almost all popular languages. This service can be used for transcription or translation, making it a useful tool for businesses and individuals alike.</p>
  
  {/* <p className="hp-p1-body"> <strong>NeuNeu.key</strong> is a service that can remove background from videos. This allows you to focus on the main subject of the video, and can also be used to change the background of a video altogether.</p>
  
  <p className="hp-p1-body"> <strong>NeuNeu.cut</strong> is a service that can split video files into video editing cuts. This can save time and effort when editing videos, as it allows you to quickly identify and separate different scenes.</p> */}
  
  <p className="hp-p1-body">neuneu.net is dedicated to providing the highest quality audio services on the market. Our team of experts in the field of AI and machine learning are constantly working to improve our services and develop new and innovative ways to enhance sound. We offer server solutions for our clients so they can install our services on their own server or utilize our infrastructure.</p>
  
  <p className="hp-p1-body">Don't just take our word for it, try our services for yourself and see the difference neuneu.net can make. We're confident that our services will help you to achieve the highest level of audio quality, and we look forward to the opportunity to work with you.</p>
        </Card>
        );
      }
    function Terms() {
    
        return (
          <Card
          hoverable
          cover={
            <img
              alt="example"
              src={TermsImg}
              height={170}
              className="hp-img-cover"
            />
          }
        >
<h2>Registration</h2>
<p className="hp-p1-body">Registration on the platform is partially moderated. The terms of confidentiality are described in the section "Confidentiality Agreement".</p>

<h2>Topics of the platform</h2>
<p className="hp-p1-body">The key thematic areas of the platform are:</p>
<ul>
  <li className="hp-p1-body"><strong>Audio and video data processing,</strong></li>
  <li className="hp-p1-body"><strong>Analysis and processing of audio and video data,</strong></li>
  <li className="hp-p1-body"><strong>Decryption of audio data,</strong></li>
  <li className="hp-p1-body"><strong>Conversion of audio data,</strong></li>
  <li className="hp-p1-body"><strong>Creation of audio and video content using neural networks.</strong></li>
</ul>

<h2>Site language</h2>
<p className="hp-p1-body">The site is written in Ukrainian or English.</p>

<h2>The procedure for providing services:</h2>
<ol>
  <li className="hp-p1-body">A person shall register in accordance with the procedure specified in the registration section.</li>
  <li className="hp-p1-body">The user is given the opportunity to test the services of the NeuNeu platform for free</li>
  <li className="hp-p1-body">The user deposits funds to the platform account.</li>
  <li className="hp-p1-body">The user selects the service, uploads the source file, applies the selected service to the data contained in the file and receives the file(s) with the processed data.</li>
  <li className="hp-p1-body">Payment is made at the expense of prepaid (deposited) funds according to the tariffs published on the website https://neuneu.net</li>
</ol>

<h2>Blocking profiles</h2>
<p className="hp-p1-body">In case of systematic (one or more) violations of NeuNeu rules, a moderator may block your profile.</p>
<p className="hp-p1-body">NeuNeu moderators do not like</p>
<ul>
  <li className="hp-p1-body"><strong>profanity,</strong></li>
  <li className="hp-p1-body"><strong>facts not confirmed by analysis or research,</strong></li>
  <li className="hp-p1-body"><strong>subjective assessments,</strong></li>
  <li className="hp-p1-body"><strong>personal negativity towards competitors, clients and contractors.</strong></li>
</ul>        </Card>
        );
      }
    function Private() {
    
        return (
          <Card
          hoverable
          cover={
            <img
              alt="example"
              src={PrivacyImg}
              height={170}
              className="hp-img-cover"
            />
          }
        >
          <h2>General provisions</h2>
<p className="hp-p1-body">Information Agency "VBS" LLC (hereinafter referred to as the "Organization"), realizing the importance of ensuring the confidentiality and inviolability of Personal Data of individuals, concludes with you (hereinafter referred to as the "Participant") this Confidentiality Agreement (hereinafter referred to as the "Agreement") to determine the procedure for obtaining, storing, processing, using and disclosing the Participant's Personal Data by the Organization when the Participant uses the Organization's Services and the Internet resource <a href="https://neuneu.net">https://neuneu.net</a> (hereinafter referred to as the "Website").</p>
<p className="hp-p1-body">To use the Website and the Services, the Member shall accept the terms of this Agreement. The Member has no right to use the Website and the services provided by the Website (hereinafter referred to as the "Services") in case of disagreement with the terms of this Agreement. Today the Site is focused on Ukrainian users.</p>
<h2>Terms</h2>
<p className="hp-p1-body"><b>Personal data</b> - information about a certain Participant that allows to identify this Participant as a person.</p>
<p className="hp-p1-body"><b>Publicly Available Data</b> - information (including Personal Data) to which an individual (the owner of this data) has provided access to an unlimited number of persons (including by publishing on any Internet site without restricting access to this data) or to which, in accordance with this Agreement and/or the current legislation of Ukraine, the requirement of confidentiality does not apply.</p>
<p className="hp-p1-body"><b>Account</b> means a set of information about the Participant containing the Participant's authorization data (login and password) required to identify the Participant when using the Website and Services.</p>
<p className="hp-p1-body"><b>Cookie</b> - a piece of data created by the Website and stored in the Participant's computer in the form of one or more files. Cookies do not contain Personal Data and can be blocked by the Participant at any time.</p>
<h2>Subject of the agreement</h2>
<p className="hp-p1-body">This Agreement determines the procedure for obtaining, storing, processing, using and disclosing Personal, Public and other data provided to the Organization by the Member when using the Site and Services.</p>
<p className="hp-p1-body">By using any part of the Site and the Service, the Participant grants the Organization the right to receive, store, process, use and disclose the Participant's Personal Data under the terms of this Agreement.</p>
<p className="hp-p1-body">This Agreement does not regulate, and the Organization is not responsible for the procedure for obtaining, storing, processing, using and disclosing the Participant's Personal Data by third-party enterprises and organizations that are not owned or controlled by the Organization, and individuals who are not employees of the Organization, even if the Participant has accessed the sites, software, goods or services of these persons through the Site.</p>

<p className="hp-p1-body">The purpose of obtaining, storing, processing and using the Participant's Personal, Public and other data is to protect the interests of the Participant and the Website, as well as to provide the Participant with the Services, including displaying targeted, personalized or general information, improving the quality of existing and creating new materials and services provided by the Website.</p>
<h2>Receipt, processing and use of information</h2>
<p className="hp-p1-body">When creating an Account, the Participant must provide authorization data, as well as name, surname, e-mail address and other data. After the Participant creates and uses the Account, the Organization shall be able to identify the Participant each time he/she uses the Website.</p>
<p className="hp-p1-body">The Organization uses the Participant's data to display a person or company in the catalog, as well as to form more accurate selections based on the user's interests.</p>
<p className="hp-p1-body">Most of the data that the Member stores in his/her profile is publicly available, except for the data that the Member has indicated as non-public in the profile settings.</p>
<p className="hp-p1-body">The Organization does not collect other data about the Participant, except for those that he/she has submitted through the forms on the Website.</p>
<p className="hp-p1-body">The Organization has the right to download Cookies to the Participants' computers (unless the Participant has independently restricted this option in his web browser), as well as to receive, store, process and use the information contained in the Cookies.</p>
<p className="hp-p1-body">When the Participant uses the Website and the Services on the equipment on which the Website is located, the Website automatically stores, processes and uses the Participant's data that do not relate to Personal Data, for example: IP address of the Participant, data on the Participant's location, which can be determined using his/her IP address, technical parameters of the Participant's computer, presence or absence of specific software on the Participant's computer, settings of this software, Cookies, as well as statistical information about the Participant's activity.</p>
<p className="hp-p1-body">The Organization has the right to store Personal, Publicly Available and other data of the Participant to the extent and within the terms necessary to achieve the purpose specified in this Agreement, or within the terms established by the current legislation of Ukraine, international law, or the legislation of the country of residence or stay of the Participant, or until the Participant deletes this data.</p>

<p className="hp-p1-body">In case of changes in Personal Data, the Participant shall provide the Organization with updated data by making appropriate changes to the Account on the Website. If the Participant fails to comply with this requirement, the Organization shall not be liable for any adverse consequences associated with the processing of such Personal Data.</p>
<p className="hp-p1-body">The Participant can edit and delete his/her data independently. The Organization does not keep a separate database of users, and when deleted by the Participant, the data is deleted from the site permanently.</p>
<h3>Access to information</h3>
<p className="hp-p1-body">• The Organization undertakes not to provide Personal Data to third parties for commercial purposes without the consent of the Participant to whom this data belongs. Transfer of Personal Data to third parties is allowed in the following cases:</p>
<ul>
    <li className="hp-p1-body">after obtaining the consent of the Participant to whom this information belongs;</li>
    <li className="hp-p1-body">if it is necessary to transfer Personal Data in order to fulfill the order or request of the Participant;</li>
    <li className="hp-p1-body">upon reasonable requests of state authorities entitled to receive such data;</li>
    <li className="hp-p1-body">if, in the opinion of the Organization, the Participant violates the terms of this Agreement and/or other contracts and agreements between the Organization and the Participant.</li>
</ul>
<p className="hp-p1-body">• The Participant allows the Organization to authorize other companies with which the Organization has concluded relevant agreements to receive, store and process data about the Participant (except for Personal Data), such as the Participant's IP address, Cookies, as well as statistical information about the Participant's activity to improve the quality of services and advertising information provided by these companies.</p>
<p className="hp-p1-body">• The Participant agrees that the confidentiality of the data transmitted via the Internet is not guaranteed and in the event that access to this data is obtained by third parties outside the technical means of communication under the control of the Organization, the Organization shall not be liable for any consequences.</p>
<h2>Access to information</h2>
<p className="hp-p1-body">• The Organization undertakes not to provide Personal Data to third parties for commercial purposes without the consent of the Participant to whom this data belongs. Transfer of Personal Data to third parties is allowed in the following cases:</p>
<ul>
    <li className="hp-p1-body">after obtaining the consent of the Participant to whom this information belongs;</li>
    <li className="hp-p1-body">if it is necessary to transfer Personal Data in order to fulfill the order or request of the Participant;</li>
    <li className="hp-p1-body">upon reasonable requests of state authorities entitled to receive such data;</li>
    <li className="hp-p1-body">if, in the opinion of the Organization, the Participant violates the terms of this Agreement and/or other contracts and agreements between the Organization and the Participant.</li>
</ul>
<p className="hp-p1-body">• The Participant allows the Organization to authorize other companies with which the Organization has concluded relevant agreements to receive, store and process data about the Participant (except for Personal Data), such as the Participant's IP address, Cookies, as well as statistical information about the Participant's activity to improve the quality of services and advertising information provided by these companies.</p>
<p className="hp-p1-body">• The Participant agrees that the confidentiality of the data transmitted via the Internet is not guaranteed and in the event that access to this data is obtained by third parties outside the technical means of communication under the control of the Organization, the Organization shall not be liable for any consequences.</p>
<h2>Final provisions</h2>
<p className="hp-p1-body">This Agreement shall come into force from the moment the Participant starts using the Services and shall be valid indefinitely.</p>
<p className="hp-p1-body">The Organization shall have the right to make changes and additions to this Agreement without the consent of the Participant. The new edition of the Agreement shall come into force from the moment it is posted on the Website, unless otherwise provided by the new edition of the Agreement.</p>
<p className="hp-p1-body">The Participant shall be responsible for familiarizing himself with the terms of this Agreement each time he uses the Services, as well as for compliance with the terms of this Agreement.</p>
<p className="hp-p1-body">This Agreement and the relations arising in connection with it shall be governed by and construed in accordance with the laws of Ukraine.</p>
<p className="hp-p1-body">If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall remain valid and enforceable.</p>
<p className="hp-p1-body">The Participant shall have the right to contact the Organization in case of any questions or suggestions regarding this Agreement by sending an email to <a href='mailto:support@neuneu.net'>support@neuneu.net</a></p>
<p className="hp-p1-body">The Participant confirms that he has read and understood the terms of this Agreement and voluntarily accepts them.</p>
          </Card>
        );
      }
    function Public() {
    
        return (
          <Card
          hoverable
          cover={
            <img
              alt="example"
              src={PublicImg}
              height={170}
              className="hp-img-cover"
            />
          }
        >

<p>This agreement, the provisions of which are regulated by Articles 626-654 of the Civil Code of Ukraine, is an <b>offer</b>: Information Agency "VBS" LLC (hereinafter referred to as the <b>Offeror</b> in the appropriate cases) for individuals (who have full civil capacity), hereinafter each such person from the moment of registration on the Offeror's website is called the <b>User</b> (together - the Users) to conclude an agreement on the provision of the <b>Services</b> by the Offeror on the terms and conditions specified below (hereinafter - the Agreement).</p>
<p>The offer (offer) is valid from the moment it is posted on the website at <a href='https://neuneu.net'>https://neuneu.net</a> (hereinafter - the Site) and may be changed or withdrawn by the Offeror at any time without the consent of the User or any other persons.</p>
<p>Further use of the Services by the User or other use of the technical capabilities of the Site after the new version of the Agreement is posted on it will be considered the User's consent to the new terms of the offer and the new version of the Agreement.</p>
<p>The moment of termination of the offer is considered the moment of its withdrawal by the Offerer in any way or the moment of termination of the Site.</p>
<p>Full and unconditional (unconditional) acceptance of all the terms of this offer by the User is carried out by clicking on the checkbox next to the words "I agree to the terms of the offer agreement, Terms and Conditions of Use and the Confidentiality Agreement, and I consent to the processing of personal data" and clicking on the "Register" button on the Site.</p>
<p>The Offeror reserves the right to reject a person's request for registration without warning and explanation.</p>
<p>By accepting (accepting) the offer, the User confirms that he is familiar with all the essential terms of the Agreement, undertakes to comply with the rules, terms and conditions established by it and agrees to provide him with the Services in the manner and on the terms specified by the Offeror in this offer. </p>
<p>• For the correct perception of the provisions of the offer, the following terms should be understood and used in the following meanings:</p>
<p><b>User</b> - a person who has passed the registration procedure on the Site <a href="https://neuneu.net">https://neuneu.net</a></p>
<p><b>User Profile</b> - User's account on the Website <a href="https://neuneu.net">https://neuneu.net</a> containing the information provided by the User about himself/herself.</p>
<p><b>Personal Account</b> - the interface of interaction with the Website <a href="https://neuneu.net">https://neuneu.net</a> within which the User has the opportunity to enter data into the User Profile at his own discretion, as well as receive data related to the interaction of the User and the Offerer using the Website <a href="https://neuneu.net">https://neuneu.net</a></p>
<p>• Services - services that the Offeror provides to Users on a paid basis, namely:</p>
<p><b>NeuNeu.voice</b> - increasing the sampling rate of voice recording. It can be used only for human voice recordings.</p>
<p><b>NeuNeu.noise</b> - removes noise, clicks and clicks from any recordings.</p>
<p><b>NeuNeu.music</b> - decomposition of a musical composition into 2-4 tracks or separation of tracks into a separate recording (vocals, drums, bass, other instruments)</p>
<p><b>NeuNeu.reco</b> - transformation of voice message into text message</p>
<p><b>NeuNeu.key</b> - removing the background in a video message</p>
<p><b>NeuNeu.cut</b> - detection of editing joints in a video message and returning the result in the form of separate files cut at the joints.</p>
<p><b>Subscription</b> - payment for access to the Website Services <a href="https://neuneu.net">https://neuneu.net</a> which is carried out in accordance with the tariffs displayed on the site.</p>
<p>The use of the Site is subject to the following conditions:</p>
<p>• The Offeror undertakes to provide access to the Service (Services) if the User has paid for such access.</p>
<p>If the User subscribes, the Company provides him with access to the Services included in the current list of Services, in the amount paid by the User, available by subscription, which is available at the link <a href="https://neuneu.net">https://neuneu.net</a> .</p>
<p>• The Offeror at its sole discretion has the right to change the tariffs for the Services available by subscription.</p>
<p>When paying for access to the Services, the User also pays the commission of the payment system. At its own discretion, the User may refuse to pay for such access.</p>
<h1> Payment Procedure </h1>
<p>After the User pays for access to the Services, the Offeror sends an electronic receipt to the User to the e-mail address specified by the User.</p>
<p>The Offeror shall <b>refund</b> to the User the funds paid by the latter in case the access to the Services was not provided due to the <b>fault</b> of the Offeror.</p>
<p>The Offeror has the right to refuse to <b>refund</b> the User for providing access to the Services if the Services were not provided to the User through his <b>fault</b> or due to <b>inadequate quality</b> of the Internet provider's services.</p>
<p>In case the User has technical problems with access to the materials on the <a href='https://neuneu.net'>Site</a>, he can contact the Offeror by e-mail <a href='mailto:business@neuneu.net'>business@neuneu.net</a>, and the Company shall provide a response to the User within 48 hours indicating the time when the problem will be eliminated or explanation of the necessary actions to eliminate the problem.</p>
<h2> Data Processing </h2>
<p>From the moment of acceptance (acceptance) of the offer, the User gives the Offerer consent to the processing of his (the User's) personal data by the latter, and also agrees that the Offerer has complied with all the conditions provided for by the current legislation necessary for the processing of personal data, and the User is duly informed of his rights in this regard.</p>
<p>The processing of the User's personal data means any actions of the Offeror related to the collection, accumulation, registration, adaptation, modification, use, dissemination, depersonalization and destruction of the above data in order to provide the Services.</p>
<p>From the moment of acceptance (acceptance) of the offer, the User agrees to receive information messages to the e-mail address and/or mobile phone number of the User specified by him in his personal account on the Site.</p>
<h3> Liability </h3>
<p>The Offeror does not compensate the User for any losses and does not bear any responsibility for:</p>
<ul>
    <li>any losses of the User (including, but not limited to, direct or indirect losses, material losses or moral damage, other negative consequences of property or other nature) in case of change or termination of this offer or termination of the use of the Services;</li>
    <li>the Customer's failure to receive legal services from the Professional Contractors or their improper (in the opinion of the Customer) provision, as well as for the Customer's failure to fulfill its obligations to the Professional Contractor, and the Offeror does not make any compensation payments or reimbursement in any form in favor of the User;</li>
</ul>
<h1>Consequences of User Actions</h1>
<ul>
  <li>The consequences of the User's actions committed by him with the help of the technical capabilities of the Site.</li>
  <li>Loss by the User of the login and/or password required to access the personal account on the Website (regardless of whether such loss occurred due to negligence or illegal actions of third parties). In any case, actions performed with the help of the technical capabilities of the Website using the login and password generated and used by the User for his identification on the Website and authorization in the personal account shall be considered actions of such User.</li>
  <li>Violation of the rights and legitimate interests of third parties that occurred as a result of the User's actions, including through the dissemination of any information by the User using the technical capabilities of the Site.</li>
  <li>Failure to achieve the desired end result by the User, which he expected to receive as a result of using the Services, and compliance of the Services with the User's expectations.</li>
  <li>Error-free and uninterrupted operation of the Site, termination of the User's access to the Site if it was due to technical problems in the functioning of hardware or software.</li>
</ul>
<h2>Payment for Services</h2>
<p>The User agrees that the Offeror independently determines the amount and procedure of payment for certain types of Services. The Company informs the User about the prices, available payment methods and the need for its implementation using the technical capabilities of the Site or by sending messages to the e-mail address. The User acquires the right to receive, within the period specified by the Offerer, certain types of Services for which payment is provided, after the Offerer receives the funds paid by the User to its own bank account.</p>
<h3>Moderation of Information</h3>
<p>The Offeror, without paying any compensation to the User, has the right to moderate the information (change, suspend, delete) posted by the User on the Site using its technical capabilities (including in the personal account), if such information violates the requirements of the current legislation of Ukraine or the restrictions established by it or the rights and legitimate interests of the Offeror or third parties holding the relevant rights.</p>
<h4>Restrictions on User Posts</h4>
<p>The User undertakes not to post on the Site information, posts and comments that are advertising political parties or religious organizations, or contain signs of pornography, calls for a change in the state system, promotion of the use of drugs, humiliate third parties or groups of persons by ethnicity, sexual orientation, appearance or religious beliefs.</p>
<h1>Moderation and Deletion of User Content</h1>
<p>The Offeror has the right to delete any information, posts and comments published by the User on the Website without the consent of the User due to a third party complaint, while providing the User with an explanation of the reasons for deleting these materials by sending a letter to the e-mail address specified in the User's profile.</p>

<h2>Violation of Terms and Current Legislation</h2>
<p>In case of violation by the User in any way of the terms of the Agreement and / or the rights and legitimate interests of the Offeror and / or the requirements of the current legislation of Ukraine, including, but not limited to, in the presence in the actions of the User of signs of offenses defined by regulatory legal acts in the field of criminal, administrative, civil or other legislation of Ukraine, the Offeror has the right, without paying the User any compensation, to restrict, suspend or terminate the User's access to the personal account (account) on the Site.</p>

<h3>Intellectual Property Rights</h3>
<p>Trademarks for goods and services, other intellectual property objects (including copyright objects) used on the Website, as well as the software used to provide the Services, the design of the Website web pages or its individual elements are the intellectual property of the Offeror or other relevant legal right holders. The use by the User of any of the above objects without the permission of the Offerer or other legal right holder is illegal and may serve as grounds for bringing the User and/or guilty third parties to responsibility in accordance with the current legislation of Ukraine.</p>
<p>Any correspondence (exchange of messages) between the Company and the User shall be deemed to be carried out within the framework of and in connection with the execution of the Agreement, if it occurs after the acceptance (acceptance by the User) of the offer (offer) until the termination of the offer or the User's refusal to use the Services or termination of use on other grounds specified in the Agreement.</p>
<p>Any notices of the Offeror addressed to the User shall be deemed to be duly delivered to the latter if they are sent to the e-mail address and/or mobile phone number of the User specified by him in his personal account on the Site and/or through the technical capabilities of the Site.</p>
<p>The User independently ensures the maintenance of the reliability, accuracy and completeness of their personal data posted in their personal account on the Website.</p>
<p>The User shall bear the risks associated with failure to maintain the reliability, accuracy and completeness of their personal data, including if this has led to the impossibility of receiving the Offeror's Services.</p>
<p>The Offeror does not enter into a representative relationship with any of the Users or other similar relationships that would allow the Offeror to be identified with any of the Users.</p>
<p>Disputes between the User and the Offeror related to the provision of the Services shall be resolved in accordance with the provisions of the current legislation of Ukraine.</p>
<p>The Offeror does not act as a representative of any of the parties in disputes between Users and does not act as an independent participant in such disputes.</p>
<p>The User has the right to terminate the use of the Services by deleting their own account on the Site and related information.</p>
<p>At the User's request, the Offeror shall delete the User's own profile (account) within 48 hours.</p>
<p>From the moment of deletion of the User's own account on the Website, the User loses the right to access the personal account on the Website and use the Services, and the Offeror is released from any obligations under the Agreement to such User.</p>
<p>In this case, the User is not entitled to any compensation or reimbursement in any form by the Offerer due to the termination of the use of the Services, including the Offerer does not return to the User the funds, if any, paid by the User for the provision of certain types of Services, the possibility of using which the User has lost due to the deletion of his own account on the Site.</p>
<p>The Offeror provides the User with the Services subject to the Privacy Policy available at the link <a href="https://neuneu.net">https://neuneu.net</a>.</p>

            </Card>
        );
      }
    
    const tabListNoTitle = [
        {
        key: "about",
        tab: "About",
        },
        {
        key: "mediakit",
        tab: "Media Kit",
        },
        {
        key: "terms",
        tab: "Terms of Service",
        },
        {
        key: "private",
        tab: "Privacy Policy",
        },
        {
        key: "public",
        tab: "Public Offer",
        },
    ];


    const contentListNoTitle = {
        about: <About/>,
        mediakit: <Mediakit/>,
        terms: <Terms/>,
        private: <Private/>,
        public: <Public/>,

    };

    const onTabChange = (key, type) => {

        setNoTitleKey(key);

    };

  return (
  <Card
            tabList={tabListNoTitle}
            activeTabKey={noTitleKey}
            onTabChange={(key) => {
              onTabChange(key, "noTitleKey");
            }}
          >
            {contentListNoTitle[noTitleKey]}
</Card>
  );
}