import {useState, useContext} from "react";
import { Link } from "react-router-dom";

import { Row, Col, Form, Input, Button } from "antd";

import LeftContent from "../leftContent";
import Footer from "../footer";
import { UserContext } from "../../../context/UserContext";
import { GoogleLogin } from '@react-oauth/google';


export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [, setToken] = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const passwordPattern = /^(?=.*\d)(?=.*[a-z]).{8,}$/i;

  async function login(access_token) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ message: access_token }),
    };
    const response = await fetch(`${apiUrl}/api/google`,  requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage2(data.detail);
      setErrorMessage(true);
    } else {
      setToken(data.access_token);
    }
  };

  const submitRegistration = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, hashed_password: password }),
    };

    const response = await fetch(`${apiUrl}/api/users`, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage2(data.detail);
      setErrorMessage(true);
    } else {
      setToken(data.access_token);
    }
  };

  const handleSubmit = (e) => {
    if (e && typeof e === "object" && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
  
    // Password validation
    const passwordPattern = /^(?=.*\d)(?=.*[a-z]).{8,}$/i;
  
    if (!passwordPattern.test(password)) {
      setErrorMessage2(
        <div style={{ marginBottom: "8px"}}>
        Your password must met the requirements:
        <ul style={{ marginTop: "8px", color: "red" }}>
          <li>at least 8 characters long</li>
          <li>contain at least one lowercase letter</li>
          <li>contain at least one digit</li>
        </ul>
        Please try again with a stronger password.
      </div>
      );
      
      setErrorMessage(true);
      return;
    }
  
    if (password !== confirmationPassword) {
      setErrorMessage2(<div style={{ marginBottom: "8px", color: "red" }}>Please ensure that the passwords match.</div>);
      setErrorMessage(true);
      return;
    }
  
    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailPattern.test(email)) {
      setErrorMessage2(<div style={{ marginBottom: "8px", color: "red" }}>Please enter a valid email address.</div>);
      setErrorMessage(true);
      return;
    }
  
    // All validations passed, submit registration
    submitRegistration();
  };
  return (
  
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <span className="hp-d-block hp-p1-body hp-text-color-dark-0 hp-text-color-black-100 hp-font-weight-500 hp-mb-6">SIGN UP FOR FREE</span>
            <h1>Create Account</h1>
            <p className="hp-mt-8 hp-text-color-black-60">
              Please sign up to your personal account if you want to use all our
              premium products.
            </p>

            <Form
              layout="vertical"
              name="basic"
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={handleSubmit}
            >
              <Form.Item label="E-mail :" value={email}>
                <Input id="validating" value={email}
              onChange={(e) => setEmail(e.target.value)}/>
              </Form.Item>

              <Form.Item label="Password :" value={password}>
                <Input.Password id="password" value={password}
              onChange={(e) => setPassword(e.target.value)}/>
              </Form.Item>

              <Form.Item label="Confirm Password :" value={confirmationPassword}>
                <Input.Password id="confirm-password" value={confirmationPassword}
              onChange={(e) => setConfirmationPassword(e.target.value)}/>
              </Form.Item>
              { errorMessage == true && errorMessage2 }
              <Form.Item >
                <Button block type="primary" htmlType="submit">
                  Sign up
                </Button>
              </Form.Item>
            </Form>
            <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
            <GoogleLogin 
             theme="filled_blue"
             logo_alignment="center"
             useOneTap
              onSuccess={credentialResponse => {
                login(credentialResponse.credential);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
            </div>
            
            
           
            <div className="hp-form-info hp-text-center">
              
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                Already have an account?
              </span>

              <Link
                to="/authentication/login"
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
              >
                Login
              </Link>
            </div>
            
            <Footer />
          </Col>
          
        </Row>
      </Col>
    </Row>
  );
};