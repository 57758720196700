import React, { useState, useRef } from "react";
import "./abplayer.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop, faPause } from "@fortawesome/free-solid-svg-icons";

const App = (src1) => {
  const [track, setTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioTime, setAudioTime] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [disabledButton1, setDisabledButton1] = useState(false)
  const [disabledButton2, setDisabledButton2] = useState(true)
  const [disabledButton3, setDisabledButton3] = useState(true)
  const audioRef = useRef(null);
  const inputRef = useRef();

  const handlePlayTrack1 = () => {
    setTrack(1);
    setIsPlaying(true);
    audioRef.current.src = src1.src1;
    audioRef.current.currentTime = currentTime;
    audioRef.current.preload = 'auto'
    audioRef.current.play();
    setDisabledButton1(true)
    setDisabledButton2(false)
    setDisabledButton3(false)
  };

  const handlePlayTrack2 = () => {
    setTrack(2);
    setIsPlaying(true);
    audioRef.current.src = src1.src2;
    audioRef.current.currentTime = currentTime;
    audioRef.current.preload = 'auto'
    audioRef.current.play();
    setDisabledButton1(false)
    setDisabledButton2(true)
    setDisabledButton3(false)
  };
  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setDisabledButton3(false)
      setIsPlaying(true);
    }
    if (!track) {setTrack(1);setIsPlaying(true);
    audioRef.current.src = src1.src1;
    audioRef.current.play();
    setDisabledButton1(true);
    setDisabledButton2(false);
    setDisabledButton3(false)}
  };
  const handleStop = () => {
      audioRef.current.pause();
      setIsPlaying(false);
      setDisabledButton3(true)
      audioRef.current.currentTime = 0
  };

  const handleAudioLoadedData = () => {
    setAudioDuration(audioRef.current.duration);
  };

  const handleAudioTime = () => {
    setAudioTime(audioRef.current.currentTime);
    if (audioRef.current.currentTime === audioRef.current.duration) {setIsPlaying(false);setDisabledButton3(true); audioRef.current.currentTime = 0}
  };
  const handleProgressBar = (e) => {
    
    const rect = inputRef.current.getBoundingClientRect();

    const progress = ((e.clientX - rect.left)/rect.width) * audioDuration;

    setCurrentTime(progress);
    audioRef.current.currentTime = progress
  };

  return (
    <div>
      <audio
        ref={audioRef}
        onTimeUpdate={(e) => {setCurrentTime(e.target.currentTime); handleAudioTime()}}
        onLoadedData={handleAudioLoadedData}
      />
      <div className={src1.bcolor}>
      <div ref={inputRef} onClick={handleProgressBar} className="progress__container" >
        <div className="progress__bar" id="progress__fill"
        style={{
          width: `${(audioTime / audioDuration) * 100 || 0}%`
        }}> </div>
      </div>
      <div className="ab__controls">
        <button
          className="ab__button"
          id="a__button"
          disabled = {disabledButton1}
          onClick={handlePlayTrack1}
          
        >
          source
        </button>
        <button
          className="ab__button"
          id="b__button"
          onClick={handlePlayTrack2}
          disabled = {disabledButton2}
        >
          result
        </button>
      </div>
      <div className="play__stop__controls">
        <button
          className="play__pause__button"
          id="play__button"
          onClick={handlePlayPause}
         
        >
         {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
        </button>
        <button
          className="play__pause__button"
          id="stop__button"
          onClick={handleStop}
          disabled = {disabledButton3}
        >
          <FontAwesomeIcon icon={faStop} />
        </button>
      </div>
    </div>
    </div>
    
    
  );
};

export default App;