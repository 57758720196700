import React from "react";

import { Card, Row, Col } from "antd";
import { Voice } from "react-iconly";

export default function OrdersFeatureCard(props) {
  const { minutes, price } = props;
  return (
    <Card className="hp-border-color-black-40">
      <Row justify="center">
        <Col className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16 hp-bg-color-primary-4 hp-bg-color-dark-primary" style={{padding:"12px", maxHeight:"48px", borderRadius:"50%"}}>
          <Voice className="hp-text-color-primary-1 remix-icon" />
        </Col>

        <Col className="hp-mt-8">
          <h3 className="hp-mb-4">
            ~{minutes}
            <span className="hp-badge-text hp-ml-8 hp-text-color-primary-1">
              {price}
            </span>
          </h3>

          <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
            minutes you can process
          </p>
        </Col>
      </Row>
    </Card>
  );
}