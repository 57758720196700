import React, { useState, useEffect, useContext } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";


import { UserContext } from "../../context/UserContext";
import { Form, Table, Row, Col, Card, Button, Modal } from "antd";
import PromoCodeForm from "./PromoCodeForm";


export default function promoTable() {
  const [token] = useContext(UserContext);
  const [datadb, setDatadb] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const createPromoCode = async (values) => {
    // Call your API to create a promo code
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    };

    const response = await fetch(`${apiUrl}/api/promo/create`, requestOptions);
    const result = await response.json();

    if (result && result.success) {
      setIsModalVisible(false);
      fetchUser(); // To refresh the table with the new promo code
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deletePromoCode = async (promoId) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ id: promoId }),
    };
  
    const response = await fetch(`${apiUrl}/api/promo/${promoId}`, requestOptions);
    const result = await response.json();
  
    if (result && result.success) {
      setDatadb(datadb.filter((item) => item.key !== promoId));
    }
  };
  

  const fetchUser = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`${apiUrl}/api/promo/all`, requestOptions);
    const fetchedData = await response.json();

    setDatadb(fetchedData);

    
  };
  useEffect(() => {
    fetchUser();
  }, []);
  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Sum',
      dataIndex: 'billing',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Activated by",
      dataIndex: "activatedby",
    },
    {
      title: "Activated",
      dataIndex: "activate",
      render: (value) => (value ? <CheckOutlined /> : <CloseOutlined />),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    setSelectedRowKeys(extra.currentDataSource.map((item) => item.key));
  }

  return (
    <Card className="hp-border-color-black-40">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="primary"
          onClick={showModal}
          style={{
            minWidth: "150px",
            whiteSpace: "nowrap",
            display: "inline-flex",
          }}
        >
          Add Promo Code
        </Button>

        <Button
          type="danger"
          onClick={() => deletePromoCode(selectedRowKeys[0])}
          disabled={selectedRowKeys.length !== 1}
          style={{
            minWidth: "150px",
            whiteSpace: "nowrap",
            display: "inline-flex",
            marginLeft: "10px",
          }}
        >
          Delete
        </Button>
      </div>
      <Modal
        title="Add Promo Code"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <PromoCodeForm form={form} onCreate={createPromoCode} />
      </Modal>
      <Row>
        <Col className="hp-mb-16" span={21}>
          <h4>Promos</h4>
        </Col>



        <Col span={24}>
        <Table
          rowSelection={{
            type: "radio",
            selectedRowKeys,
            onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
          }}
          columns={columns}
          dataSource={datadb}
          onChange={onChange}
          scroll={{ x: 500 }}
        />
        </Col>
      </Row>


    </Card>
  );
}
