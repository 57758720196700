import React, { useRef, useContext, useState, useEffect } from "react";

import { Layout, Button, Row, Col, Typography } from "antd";
import { RiCloseLine, RiMenuFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion/dist/framer-motion';

import HeaderUser from "./HeaderUser";

import HeaderNotifications from "./HeaderNotifications";
import HeaderThemeswitch from "./HeaderThemeswitch";
import AdminButton from "./AdminButton";
import HeaderText from "./HeaderText";
import RedeemButton from "./RedeemButton"
import { UserContext } from "../../../context/UserContext";

const { Header } = Layout;
const { Text } = Typography;


export default function MenuHeader(props) {
  const { setVisible, balance, username } = props;
  const [token, setToken] = useContext(UserContext);
  const handleLogout = () => {
    setToken(null);
  };



  

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };



  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);

  };

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="hp-mobile-sidebar-button hp-mr-24">
          <Button
            type="none"
            ghost
            className="hp-mobile-sidebar-button hp-border-none"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
              />
            }
          />
        </Col>



        
          <HeaderText />
        

        <Col>
          <Row align="middle">
          {username === "alx.sel@gmail.com" && (<AdminButton/>)}
          {token != null && (<RedeemButton/>)}
            {/* <HeaderLanguages /> */}
            <HeaderThemeswitch />
            {token != null ? (<>
            <HeaderNotifications/>
            <HeaderUser credits={balance}/>
            </>
            ) : (
              <>
            <Link to='/authentication/register'>
              <Button type="text" className="hp-px-sm-16 hp-py-sm-8 hp-px-32 hp-py-16 hp-ml-sm-8 hp-text-color-black-80 hp-text-color-dark-30">SignUp</Button>
          </Link>
          <Link to='/authentication/login'>
              <Button type="primary" className="hp-px-sm-16 hp-py-sm-8 hp-px-32 hp-py-16 hp-ml-sm-0 hp-ml-8">Login</Button>
              </Link>
              </>
            )}
            
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Header>
      <Row justify="center" className="hp-w-100">
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
            className="hp-w-100"
          >
            {headerChildren()}
          </motion.div>
        </Col>
      </Row>
    </Header>
  );
};