import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory ,useLocation } from 'react-router-dom';

import { Row, Col, Form, Input, Button } from "antd";

import LeftContent from "../authentication/leftContent";
import Footer from "../authentication/footer";


export default function Forgot() {
  const [forgotCode, setforgotCode] = useState(null);
  const [newpassword, setNewPassword] = useState("");
  const [newpassword2, setNewPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [changedPass, setChangedPass] = useState(null)
  const location = useLocation()
  const link = location.pathname.replace('/forgot/','')
  const apiUrl = process.env.REACT_APP_API_URL;
  const submitPasswordChange = async () => {

    async function passchange() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: JSON.stringify(
          `grant_type=&username=${link}&password=${newpassword}&scope=&client_id=&client_secret=`
        ),
      };
  
      const response = await fetch(`${apiUrl}/api/resetpass`, requestOptions);
      const data = await response.json();
      console.log(data)
      setChangedPass(data)

}
  passchange()
  };

  useEffect(() => {
    if (errorMessage === false || errorMessage === true) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
    
  }, [errorMessage]);
  useEffect(() => {
    fetch(`${apiUrl}/forgot/${link}`)
      .then((response) => response.json())
      .then((data) => {
        setforgotCode(data)
      });
  }, []);
  const handleSubmit = async () => {
    if (newpassword === newpassword2 && newpassword.length > 5) {
      submitPasswordChange();
    } else {
      setErrorMessage(true);

    }
    form.resetFields();
  };
  const [form] = Form.useForm();
  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            {forgotCode ? (changedPass ? <><h1>Password changed successfully</h1>
            <p className="hp-mt-8 hp-text-color-black-60">
              You can now go to the  <Link
                to="/authentication/login"
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
              > login page </Link>
            </p></> : <><h1>Reset Password</h1>
            <p className="hp-mt-8 hp-text-color-black-60">
              Email verification is done. Please choose another password
            </p>

            <Form
              layout="vertical"
              name="basic"
              className="hp-mt-sm-16 hp-mt-32"
              form={form}
              onFinish={handleSubmit}
            >
              <Form.Item label="Password :" value={newpassword}>
                <Input.Password 
                  value={newpassword}
                  id="password"
                  placeholder="At least 6 characters"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Confirm Password :"
              value={newpassword2}>
                <Input.Password
                  id="confirm-password"
                  placeholder="At least 6 characters"
                  value={newpassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
              </Form.Item>
              { errorMessage == true && <p style={{ color: "red" }}>Ensure that the passwords match and greater than 5 characters</p> }
              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button block type="primary" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>

            <div className="hp-form-info hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                Go back to
              </span>

              <Link
                to="/authentication/login"
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
              >
                Login
              </Link>
            </div>
            </>) : <> <h1 align="middle">Your reset link is expired</h1>
            <p align="middle" className="hp-mt-8 hp-text-color-black-60">
              You can try to reset your password again<Link to="/authentication/recover-password"> here </Link>
            </p></>}
            <Footer />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}