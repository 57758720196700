import React, { useState, useRef, useEffect } from 'react'

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Row, Col, Avatar, Button, Tag } from "antd";
import { theme } from '../../../redux/customise/customiseActions';
import { RiMoonClearFill, RiSunFill } from "react-icons/ri";

export default function HeaderThemeswitch() {
  const [active, setActive] = useState(false);

  // Redux
  const customise = useSelector(state => state.customise)
  const dispatch = useDispatch()

  // Location
  const location = useHistory()

  // Theme Active
  let themeRef = useRef(null)
  themeRef = [];

  const [themeLocal, setThemeLocal] = useState()
  const [themeClickCheck, setThemeClickCheck] = useState(false)



  useEffect(() => {
      if (themeClickCheck == false) {
          if (location.location.search == "?theme=dark") {
              localStorage.setItem("theme", "dark")
              setThemeLocal("dark")


          } else if (location.location.search == "?theme=light") {
              localStorage.setItem("theme", "light")
              setThemeLocal("light")

          }
      }

      if (localStorage) {
          setThemeLocal(localStorage.getItem("theme"))
      }


  }, )

  function themeClick(index) {
      setThemeClickCheck(true)

      if (index == 0) {
          document.querySelector("body").classList.replace("dark", "light")
          localStorage.setItem("theme", "light")
          setThemeLocal("light")

          dispatch(theme("light"))
      } else {
          document.querySelector("body").classList.replace("light", "dark")
          localStorage.setItem("theme", "dark")
          setThemeLocal("dark")

          dispatch(theme("dark"))
      }


  }

  return (
        <Col className="hp-languages hp-mr-8">
      <div className="hp-d-flex-center">
        
        {themeLocal === 'dark' ? (<Button type="link"  shape="circle" icon={<RiMoonClearFill className="remix-icon" />} size='default' ref={(ref) => { themeRef[0] = ref }} onClick={() => themeClick(0)} />)
        : (<Button type="link" shape="circle"  icon={<RiSunFill className="remix-icon" />} size='default' ref={(ref) => { themeRef[1] = ref }} onClick={() => themeClick(1)} />)}
        
      </div>
    </Col>

  );
};