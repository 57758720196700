import React, {useState, useEffect, useContext} from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Badge, Row, Col, Dropdown, Divider, Avatar } from "antd";
import { NotificationBing, TickCircle } from 'iconsax-react';

import { UserContext } from "../../../context/UserContext";

export default function HeaderNotifications() {
  const [fetchedData, setFetchedData] = useState(null);
  const [token, setToken] = useContext(UserContext);
  const [servicesSum, setServicesSum] = useState(0);
  const direction = useSelector(state => state.customise.direction)
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchservices = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`${apiUrl}/api/services/me`, requestOptions);
    const data = await response.json();
    setFetchedData(data);
  };

  useEffect(() => {
    fetchservices();
    const interval = setInterval(() => {
      fetchservices();
    }, 30000); // 30000 milliseconds = 30 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    if (fetchedData !== null) {
      const sums = Object.values(fetchedData).reduce((a, b) => a + b, 0);
      setServicesSum(sums);
    }
  }, [fetchedData]);

  async function updateProcessedValue(serviceName) {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
  
    const response = await fetch(`${apiUrl}/api/services/me/${serviceName}`, requestOptions);
    if (response.ok) {
      // Refresh fetchedData to reflect the updated value in the UI
      fetchservices();
    } else {
      // Handle error
      console.error("Error updating processed value");
    }
  }

  function renderNotification(key, value) {
    const serviceName = {
      demu: "NeuNeuMusic",
      voicefx: "NeuNeuVoice",
      denoise: "NeuNeuNoise",
      voskw: "NeuNeuReco",
      scdet: "NeuNeuCut",
      backdel: "NeuNeuKey",
      translate: "NeuNeuText"
    };
    const renderLink = () => {
      switch (key) {
        case "demu":
          return <Link to="/services/neuneumusic">{renderContent()}</Link>;
        case "voicefx":
          return <Link to="/services/neuneuvoice">{renderContent()}</Link>;
        case "denoise":
          return <Link to="/services/neuneunoise">{renderContent()}</Link>;
        case "scdet":
          return <Link to="/services/neuneucut">{renderContent()}</Link>;
        case "voskw":
          return <Link to="/services/neuneureco">{renderContent()}</Link>;
        case "backdel":
          return <Link to="/services/neuneukey">{renderContent()}</Link>;
        case "translate":
          return <Link to="/services/neuneukey">{renderContent()}</Link>;
        // Add more cases for other keys if needed
        default:
          return renderContent();
      }
    };
    const renderContent = () => (
      <React.Fragment>
        <Col className="hp-mr-12">

        </Col>
  
        <Col flex="1 0 0">
        <p className="hp-d-block hp-font-weight-500 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-mb-4">
          You have {value} file(s) processed using {serviceName[key]} service. 
          </p>
        </Col>
      </React.Fragment>
    );
  
      return (
        <Row
          className="hp-cursor-pointer hp-border-radius hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-py-12 hp-px-10"
          style={{ marginLeft: -10, marginRight: -10 }}
          onClick={() => updateProcessedValue(key)}
        >
          {renderLink()}
        </Row>
      );
    }
  const notificationMenu = (
    <div className="hp-notification-dropdown hp-border-radius hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-24" style={{ marginTop: 23 }}>
      <Row wrap={false} align="middle" justify="space-between" className="hp-mb-16">
        <Col className="h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mr-64">
          Notifications
        </Col>

        <Col className="hp-badge-text hp-font-weight-500 hp-text-color-black-80 hp-ml-24">
          {servicesSum} New
        </Col>
      </Row>

      <Divider className="hp-mt-0 hp-mb-4" />

      <div className="hp-overflow-y-auto hp-px-10" style={{ maxHeight: 400, marginRight: -10, marginLeft: -10 }}>
        {fetchedData && Object.entries(fetchedData).map(([key, value]) => {
          if (value > 0) {
            return renderNotification(key, value);
          }
          return null;
        })}
      </div>
    </div>
  );

  return (
    <Col className="hp-d-flex-center">
      <Button
        ghost
        type="primary"
        className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
        icon={
          <Dropdown overlay={notificationMenu} placement="bottomRight">
            <div className="hp-position-relative">
              <div className="hp-position-absolute" style={direction == "rtl" ? { left: -5, top: -5 } : { right: -5, top: -5 }}>
              <Badge size="default" count={servicesSum}></Badge>
              </div>

              <NotificationBing size="22" className="hp-text-color-black-80 hp-text-color-dark-30" />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
};