import React, { useRef } from "react";

import { Row, Col, Button } from "antd";
import { Route, Switch, Link } from "react-router-dom";
import UsersTable from "./usersTable";
import ServicesTable from "./servicesTable";
import PromoTable from "./promoTable";

export default function Table() {
    let myRef = useRef(null)
    myRef = [];
    return (

    <Row gutter={[32]}>
        <Col span={24} className="hp-mb-32 hp-table-content-col">
            <Row gutter={[32, 32]}>
            <Col align="middle" span={24}>
            <Link to="/adminpanel/users">
            <Button type="primary" className="hp-btn-outline hp-hover-bg-primary-1" style={{minWidth: '150px',  whiteSpace: 'nowrap',
  display: 'inline-flex'}}>
                Users Table
            </Button>
            </Link>
            <Link to="/adminpanel/services">
            <Button type="primary" className="hp-btn-outline hp-text-color-secondary-1 hp-border-color-secondary-1 hp-hover-bg-secondary-1" style={{minWidth: '150px',  whiteSpace: 'nowrap',
  display: 'inline-flex', marginLeft: "10px"}}>
                Services Table
            </Button>
            </Link>
            <Link to="/adminpanel/promo">
            <Button type="primary" className="hp-btn-outline hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" style={{minWidth: '150px',  whiteSpace: 'nowrap',
  display: 'inline-flex', marginLeft: "10px"}}>
                Promo Table
            </Button>
            </Link>
            </Col>
                <Col span={24} ref={(ref) => { myRef[5] = ref }}>
                <Switch>
              <Route path="/adminpanel/users">
                <UsersTable />
              </Route>
              <Route path="/adminpanel/services">
                <ServicesTable />
              </Route>
              <Route path="/adminpanel/promo">
                <PromoTable />
              </Route>
            </Switch>
                
                </Col>

        </Row>
      </Col>
    </Row>
);
}