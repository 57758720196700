import React, { useState } from "react";

import { Row, Col, Input, Tag } from "antd";
import {
  RiFlagLine,
  RiPriceTag3Line,
  RiCustomerServiceLine,
  RiMailSendLine,
  RiMailLine,
  RiBasketballLine,
  RiRadioButtonFill,
  RiDonutChartLine,
  RiSettingsLine
} from "react-icons/ri";

import PageContent from "../../layout/components/content/page-content";
import MenuFAQ from "./menu";
import CollapseItemFAQ from "./collapseItem";



const data = [
  {
    icon:
      <RiFlagLine
        className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
        size={20}
      />,
    title:
      <span className="hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
        FAQ
      </span>,
    items: [
      {
        item: [
          {
            icon:
              <RiRadioButtonFill
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How do I sign up/sign in?</span>,
            
            text: <p className="hp-p1-body">If you do not have a neuneu.net account or are not registered in the system, you can register by entering your e-mail address and the desired login password.

            If you have an account or are registered at neuneu.net, you can log in using your email address and password.
            
            If you want to activate your account, you need to go to your email, find the letter from the neuneu.net company and follow the link.</p>
          },
          {
            icon:
              <RiRadioButtonFill
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>What do I get after sign up?</span>,
            // text: <p className="hp-p1-body">After registration, you can get 1 free credit for testing neuneu.net services and enjoy the unlimited possibilities of NeuNeu.voice, NeuNeu.noise, NeuNeu.music, NeuNeu.reco, NeuNeu.key, NeuNeu.cut for free</p>
            text: <p className="hp-p1-body">After registration, you can get 1 free credit for testing neuneu.net services and enjoy the unlimited possibilities of NeuNeu.voice, NeuNeu.noise, NeuNeu.music, NeuNeu.reco,  NeuNeu.text for free</p>
          },
          {
            icon:
              <RiRadioButtonFill
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How can I top up my balance?</span>,
            
            text: <p className="hp-p1-body">To top up your balance, you need to click on the Pricing button in the side menu, after going to the PRICING AND PLANS Section, choose a plan convenient for you and pay for it. If you have a promo code or coupon, select REDEEM CODE in the upper right corner of the menu and enter the code.</p>
          }
        ]
      },
      {
        item: [
          {
            icon:
              <RiRadioButtonFill
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>Which languages are supported?</span>,
            
            text: <p className="hp-p1-body">English</p>
          },
          {
            icon:
              <RiRadioButtonFill
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>In which countries can I use your service?</span>,
            
            text: <p className="hp-p1-body">All countries except: Russia, North Korea, Iran</p>
          },
          
          {
            icon:
              <RiRadioButtonFill
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>What is the expiration date of payments?</span>,
            
            text: <p className="hp-p1-body">It depends on your bank and payment system</p>
          },
        ]
      },
    ]
  },
  {
    icon:
      <RiDonutChartLine
        className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
        size={20}
      />,
    title:
      <span className="hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
        Services
      </span>,
    items: [
      {
        item: [
          {
            icon:
              <RiSettingsLine
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How to use NeuNeu.voice?</span>,
            
            text: <p className="hp-p1-body">Service NeuNeu.voice can be used for improving quality of voice records you have. You need to upload your file with audio records, then you will get file with improved audio record.</p>
          },
          {
            icon:
              <RiSettingsLine
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How to use NeuNeu.noise?</span>,
            
            text: <p className="hp-p1-body">Service NeuNeu.noise can be used for cancelation of all the technical and natural noise on audio records you have. You need to upload your file with audio records, then you will get file without any noise.</p>
          },
          {
            icon:
              <RiSettingsLine
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How to use NeuNeu.music?</span>,
            
            text: <p className="hp-p1-body">Service NeuNeu.voice can be used for separating audio tracks in one musical composition. f.e.: you can listen separately voice and phonogram; or use violin of Vanessa Mae with drums of Ringo Star in your own composition. You need to upload your file with music composition, then you will get a few files with separated tracks.</p>
          }
        ]
      },
      {
        item: [
          {
            icon:
              <RiSettingsLine
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How to use NeuNeu.reco?</span>,
            
            text: <p className="hp-p1-body">Service NeuNeu.reco transforms your audio file  into text file. You need to upload your audio file with recorded voice, then you get text file with recognized text.</p>
          },
          // {
          //   icon:
          //     <RiSettingsLine
          //       size={24}
          //       className="remix-icon hp-text-color-primary-1 hp-mr-18"
          //     />,
          //   title: <span>How to use NeuNeu.key?</span>,
            
          //   text: <p className="hp-p1-body">The NeuNeu.key service automatically cuts out the background from your video - leaving only a moving object on a green background. First you upload video file and get video file with green background and .png files with transparent background. </p>
          // },
          // {
          //   icon:
          //     <RiSettingsLine
          //       size={24}
          //       className="remix-icon hp-text-color-primary-1 hp-mr-18"
          //     />,
          //   title: <span>How to use NeuNeu.cut?</span>,
            
          //   text: <p className="hp-p1-body">The NeuNeu.cut service divides the edited video into parts at the places of video cut and returns several separate short files.</p>
          // },
        ]
      },
    ]
  },
  {
    icon:
      <RiCustomerServiceLine
        className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
        size={20}
      />,
    title:
      <span className="hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
        Usage Patterns
      </span>,
    items: [
      {
        item: [
          {
            icon:
              <RiBasketballLine
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How can I increase the voice quality?</span>,
            
            text: <p className="hp-p1-body">Use NeuNeu.voice. Service NeuNeu.voice can be used for improving quality of voice records you have. You need to upload your file with audio records, then you will get file with improved audio record.</p>
          },
          {
            icon:
              <RiBasketballLine
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How can I remove the noise from audio file?</span>,
            
            text: <p className="hp-p1-body">Use NeuNeu.noise services for these purposes. Service NeuNeu.noise can be used for cancelation of all the technical and natural noise on audio records you have. You need to upload your file with audio records, then you will get file without any noise.</p>
          },
          {
            icon:
              <RiBasketballLine
                size={24}
                className="remix-icon hp-text-color-primary-1 hp-mr-18"
              />,
            title: <span>How can I separate music and vocal?</span>,
            
            text: <p className="hp-p1-body">Use NeuNeu.music for this purposes. Service NeuNeu.voice can be used for separating audio tracks in one musical composition.</p>
          }
        ]
      },
      
    ]
  },
  
    
]

export default function FAQ() {
  const [tabValue, setTabValue] = useState("tab-0");

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <PageContent
          title="Hello! How can we help you?"
          desc="or choose a category to quickly find the help you need."
          backcolor="#0770e1"
        />
      </Col>

      <MenuFAQ setTabValue={setTabValue} data={data} />

      <CollapseItemFAQ tabValue={tabValue} data={data} />
    </Row>
  );
}
