import React, { useState } from "react";

import { Col, Divider, Button, Card } from "antd";
import before_voicefx from "../../../assets/before_after/voicefx_before.jpg"
import after_voicefx from "../../../assets/before_after/voicefx_after.jpg"


import ReactCompareImage from 'react-compare-image';
import Abplayer from "../../../assets/js/abplayer"

import { Link } from "react-router-dom";

export default function Exampleses(id) {
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";

    const [noTitleKey, setNoTitleKey] = useState(id.id);

    function Neuneuvoice() {
    
        return (<Col align="middle">
          <h2>Improve <b className="hp-text-color-primary-3">Voice quality</b> with NeuNeu.<b className="hp-text-color-primary-1">voice</b></h2>
          <p className="hp-p1-body">
          Say goodbye to muffled or noisy recordings. Whether it's an interview, lecture, or phone call, our AI can eliminate background noise, and increase sample frequency up to 44100 hz.
          Transform your voice to the next level with our advanced neural network.
          </p>
          <Abplayer bcolor="player__wrapper" src1="https://beetpublic.s3.amazonaws.com/neuneuvoice/before.wav" src2="https://beetpublic.s3.amazonaws.com/neuneuvoice/after.wav"/>
          <Divider className={dividerClass} />
          <ReactCompareImage  leftImageLabel='Spectogram of Source audio' rightImageLabel='Spectogram of Processed audio' leftImage={before_voicefx}  rightImage={after_voicefx} />
          <Divider className={dividerClass} />
          <Link to="/services/neuneuvoice" >
          <Button type="primary" style={{width: "auto"}}  size="large">TRY NeuNeu.voice</Button>
          </Link></Col>
          
        );
      }
    function Neuneumusic() {
    
        return (
          <Col align="middle">
            <h2><b className="hp-text-color-primary-3">Sepa</b>|rate Music and Vocals with NeuNeu.<b className="hp-text-color-primary-1">music</b></h2>
          <p className="hp-p1-body">
          Service that isolates the vocals of a song and removes the background music. This can be useful for karaoke, music production, and other audio related applications. The result is two separate tracks, one containing only the vocals and the other containing only the instrumental background music.
          </p>
          <Divider className={dividerClass} />
          <h4 className="hp-text-color-dark-b40">Example 1</h4>
          <Divider className={dividerClass} />
          <Abplayer bcolor="player__wrapper2" src1="https://beetpublic.s3.amazonaws.com/neuneumusic/before1.wav" src2="https://beetpublic.s3.amazonaws.com/neuneumusic/after1.mp3"/>
          <Divider className={dividerClass} />
          <h4 className="hp-text-color-dark-b40">Example 2</h4>
          <Divider className={dividerClass} />
          <Abplayer bcolor="player__wrapper" src1="https://beetpublic.s3.amazonaws.com/neuneumusic/before2.wav" src2="https://beetpublic.s3.amazonaws.com/neuneumusic/after2.mp3"/>
          <Divider className={dividerClass} /> 
          <Link to="/services/neuneumusic" >
          <Button type="primary" style={{width: "auto"}}  size="large">TRY NeuNeu.music</Button></Link></Col>
        );
      }
    // function Neuneukey() {
    
    //     return (
    //       <Col align="middle">
    //       <h2>Remove <b className="hp-text-color-primary-3">Background </b> from Video with NeuNeu.<b className="hp-text-color-primary-1">key</b></h2>
    //       <p className="hp-p1-body">
    //       Service that removes the background of a video, leaving only the foreground subject. This is typically done using image or video processing techniques to separate the subject from the background, and can be useful in a variety of applications such as green-screen film production, video conferencing, and digital presentations. The result is a video with a transparent or replaced background, allowing the subject to be placed on any desired background.
    //       </p>
    //       <video className="hp-bg-black-0" style={{ borderRadius: "10px", overflow: "hidden" }} width="100%" autoPlay loop muted playsInline webkit-playsinline>
    //   <source src="https://beetpublic.s3.amazonaws.com/video/NeuNeuKey.mp4" type="video/mp4"/>

    //   Your browser does not support the video tag.
    // </video>
    // <Divider className={dividerClass} /> 
    // <Link to="/services/neuneukey" >
    //       <Button type="primary" style={{width: "auto"}}  size="large">TRY NeuNeu.key</Button></Link></Col>
    //     );
    //   }
    function Neuneunoise() {
    
        return (
          <Col align="middle">
          <h2>Remove any <b className="hp-text-color-primary-3">audio noise</b> with NeuNeu.<b className="hp-text-color-primary-1">noise</b></h2>
          <p className="hp-p1-body">
          Eliminate unwanted or distracting background noise from audio recordings. This service uses various techniques such as equalization, filtering, and noise reduction to clean up the audio and produce a clearer, more enjoyable listening experience. Whether you're working on a podcast, a video, or any other audio project, this service can help you achieve professional-quality sound that is free from distractions. The end result is an audio recording that is clean, crisp, and easy to listen to.
          </p>
          <Divider className={dividerClass} />
          <h4 className="hp-text-color-dark-b40">Example 1</h4>
          <Divider className={dividerClass} />
          <Abplayer bcolor="player__wrapper" src1="https://beetpublic.s3.amazonaws.com/neuneunoise/before1.wav" src2="https://beetpublic.s3.amazonaws.com/neuneunoise/after1.wav"/>
          <Divider className={dividerClass} />
          <h4 className="hp-text-color-dark-b40">Example 2</h4>
          <Divider className={dividerClass} />
          <Abplayer bcolor="player__wrapper2" src1="https://beetpublic.s3.amazonaws.com/neuneunoise/before2.wav" src2="https://beetpublic.s3.amazonaws.com/neuneunoise/after2.wav"/>  
          <Divider className={dividerClass} />
          <Link to="/services/neuneunoise" >
          <Button type="primary" style={{width: "auto"}}  size="large">TRY NeuNeu.noise</Button></Link></Col>
        );
      }
    // function Neuneucut() {
    
    //     return (
    //       <Col align="middle">
    //       <h2><b className="hp-text-color-primary-3">Split</b> videos with NeuNeu.<b className="hp-text-color-primary-1">cut</b></h2>
    //       <p className="hp-p1-body">
    //       NeuNeu.cut is an automatic video splitter service that allows users to upload a video and have it divided into cuts based on specific time frames or scene changes. This makes it easy for users to manage and share their videos, as they can now download each segment as a separate video file.
    //       </p>
    //       <video style={{ borderRadius: "10px", overflow: "hidden" }} width="100%" autoPlay loop muted playsInline webkit-playsinline>
    //   <source src="https://beetpublic.s3.amazonaws.com/video/NeuNeuCut.mp4" type="video/mp4"/>

    //   Your browser does not support the video tag.
    // </video>
    //       <Divider className={dividerClass} />
    //       <Link to="/services/neuneucut" >
    //       <Button type="primary" style={{width: "auto"}}  size="large">TRY NeuNeu.cut</Button></Link></Col>
    //     );
    //   }
    function Neuneureco() {
    
        return (
          <Col align="middle">
          <h2><b className="hp-text-color-primary-3">Transform</b> speech to text with NeuNeu.<b className="hp-text-color-primary-1">reco</b></h2>
          <p className="hp-p1-body">
          NeuNeu.reco is a speech-to-text service that transcribes audio files into written text. The service supports 12 different languages, making it a versatile tool for individuals and businesses with international audiences. The user uploads an audio file to the service, and after processing, the text of the speech is provided. The accuracy and speed of the transcription process make NeuNeu.reco a valuable tool for anyone in need of transcribing audio content into written text.
          </p>
          <video style={{ borderRadius: "10px", overflow: "hidden" }} width="100%" autoPlay loop muted playsInline webkit-playsinline>
      <source src="https://beetpublic.s3.amazonaws.com/video/NeuNeuReco.mp4" type="video/mp4"/>

      Your browser does not support the video tag.
    </video>
          <Divider className={dividerClass} />
          <Link to="/services/neuneureco" >
          <Button type="primary" style={{width: "auto"}}  size="large">TRY NeuNeu.reco</Button></Link></Col>
        );
      }
    const tabListNoTitle = [
        {
        key: "neuneuvoice",
        tab: "NeuNeu.voice",
        },
        {
        key: "neuneumusic",
        tab: "NeuNeu.music",
        },
        // {
        // key: "neuneukey",
        // tab: "NeuNeu.key",
        // },
        {
        key: "neuneunoise",
        tab: "NeuNeu.noise",
        },
        // {
        // key: "neuneucut",
        // tab: "NeuNeu.cut",
        // },
        {
        key: "neuneureco",
        tab: "NeuNeu.reco",
        },
    ];


    const contentListNoTitle = {
      neuneuvoice: <Neuneuvoice/>,
      neuneunoise: <Neuneunoise/>,
      neuneumusic: <Neuneumusic/>,
      // neuneucut: <Neuneucut/>,
      neuneureco: <Neuneureco/>,
      // neuneukey: <Neuneukey/>,

    };

    const onTabChange = (key, type) => {

        setNoTitleKey(key);

    };

  return (
  <Card
            tabList={tabListNoTitle}
            activeTabKey={noTitleKey}
            onTabChange={(key) => {
              onTabChange(key, "noTitleKey");
            }}
          >
            {contentListNoTitle[noTitleKey]}
</Card>
  );
}