import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Form, Input, Button, Checkbox } from "antd";

import LeftContent from "../leftContent";
import Footer from "../footer";
import { UserContext } from "../../../context/UserContext";
import { GoogleLogin } from '@react-oauth/google';

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [, setToken] = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    if (errorMessage === false || errorMessage === true) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
    
  }, [errorMessage]);

  async function login(access_token) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ message: access_token }),
    };
    const response = await fetch(`${apiUrl}/api/google`,  requestOptions);
    const data = await response.json();
    
    if (!response.ok) {
      setErrorMessage2(data.detail);
      setErrorMessage(true);
    } else {
      setToken(data.access_token);
    }
  };

  const submitLogin = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };

    const response = await fetch(`${apiUrl}/api/token`, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage2(data.detail);
      setErrorMessage(true);
    } else {
      setToken(data.access_token);
    }
  };

  const handleSubmit = (e) => {
    if (password) {submitLogin()}
    else {setErrorMessage("Ensure that the passwords match and greater than 5 characters")}
  };

  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <h1 className="hp-mb-sm-0">Login</h1>
            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
              Welcome back, please login to your account.
            </p>

            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={handleSubmit}
            >
              <Form.Item label="E-Mail :" className="hp-mb-16" value={email}>
                <Input id="error" value={email}
              onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>

              <Form.Item label="Password :" className="hp-mb-8" value={password}>
                <Input.Password id="warning2" value={password}
              onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>

              <Row align="middle" justify="space-between">
                <Form.Item className="hp-mb-0">
                  <Checkbox name="remember">Remember me</Checkbox>
                </Form.Item>

                <Link
                  className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                  to="/authentication/recover-password"
                >
                  Forgot Password?
                </Link>
              </Row>
              
              { errorMessage == true && <p  align="middle" style={{ color: "red" }}>{errorMessage2}</p> }
              <Form.Item className="hp-mt-16 hp-mb-8">

                  <Button block type="primary" htmlType="submit">
                    Sign in
                  </Button>

              </Form.Item>
            </Form>
            <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
            <GoogleLogin 
             theme="filled_blue"
             logo_alignment="center"
             useOneTap
              onSuccess={credentialResponse => {
                login(credentialResponse.credential);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
            </div>

            <Col className="hp-form-info hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-font-weight-400 hp-mr-4">
                Don’t you have an account?
              </span>

              <Link
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                to="/authentication/register"
              >
                Create an account
              </Link>
            </Col>

            <Footer />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};