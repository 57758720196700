import React, { useState } from 'react'

import { Switch, Button, Col, Row } from 'antd'
import { Link } from "react-router-dom";


export default function LandingPricing() {
    const [billedValue, setBilledValue] = useState(false);

    const billedText = `${billedValue ? "Advanced pricing" : "Simple pricing"}`;
    const [token, setToken] = useState(localStorage.getItem("beetUserToken"));

    function onChange(checked) {
        if (checked) {
            setBilledValue(true);
        } else {
            setBilledValue(false);
        }
    }

    const itemValues = [
        {
            title: `${billedValue ? "Turbo" : "Basic"}`,

            price: `${billedValue ? "100" : "5"}`,
            credits: `${billedValue ? "+ 15 Credits FREE" : " Credits"}`,

            best: false,
            button: `${billedValue ? "PAY $100" : "PAY $5"}`,
            link: `${billedValue ? "100" : "5"}`,
            list: {
                "NeuNeu.voice": billedValue ? "1150 min of" : "50 min of",
                "NeuNeu.music": billedValue ? "1150 min of" : "50 min of",
                "NeuNeu.noise": billedValue ? "1150 min of" : "50 min of",
                "NeuNeu.reco": billedValue ? "1150 min of" : "50 min of",
                // "NeuNeu.cut": billedValue ? "575 min of" : "50 min of",
                // "NeuNeu.key": billedValue ? "380 min of" : "50 min of",
            },
        },
        {
            title: `${billedValue ? "Hero" : "Starter"}`,

            price: `${billedValue ? "200" : "20"}`,
            credits: `${billedValue ? "+ 35 Credits FREE" : "+ 1 Credit FREE"}`,

            best: false,
            button: `${billedValue ? "PAY $200" : "PAY $20"}`,
            link: `${billedValue ? "200" : "20"}`,
            list: {
                "NeuNeu.voice": billedValue ? "2350 min of" : "210 min of",
                "NeuNeu.music": billedValue ? "2350 min of" : "210 min of",
                "NeuNeu.noise": billedValue ? "2350 min of" : "210 min of",
                "NeuNeu.reco": billedValue ? "2350 min of" : "210 min of",
                // "NeuNeu.cut": billedValue ? "1175 min of" : "105 min of",
                // "NeuNeu.key": billedValue ? "780 min of" : "70 min of",
            },
        },
        {
            title: `${billedValue ? "MegaTier" : "Advanced"}`,

            price: `${billedValue ? "500" : "50"}`,
            credits: `${billedValue ? "+ 100 Credits FREE" : "+ 5 Credits FREE"}`,

            best: false,
            button: `${billedValue ? "PAY $500" : "PAY $50"}`,
            link: `${billedValue ? "500" : "50"}`,
            list: {
                "NeuNeu.voice": billedValue ? "6000 min of" : "550 min of",
                "NeuNeu.music": billedValue ? "6000 min of" : "550 min of",
                "NeuNeu.noise": billedValue ? "6000 min of" : "550 min of",
                "NeuNeu.reco": billedValue ? "6000 min of" : "550 min of",
                // "NeuNeu.cut": billedValue ? "3000 min of" : "275 min of",
                // "NeuNeu.key": billedValue ? "2000 min of" : "180 min of",
            },

        },
    ];

    const listSVG = (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.99992 13.6667C3.31792 13.6667 0.333252 10.682 0.333252 7.00004C0.333252 3.31804 3.31792 0.333374 6.99992 0.333374C10.6819 0.333374 13.6666 3.31804 13.6666 7.00004C13.6666 10.682 10.6819 13.6667 6.99992 13.6667ZM6.99992 12.3334C8.41441 12.3334 9.77096 11.7715 10.7712 10.7713C11.7713 9.77108 12.3333 8.41453 12.3333 7.00004C12.3333 5.58555 11.7713 4.229 10.7712 3.2288C9.77096 2.22861 8.41441 1.66671 6.99992 1.66671C5.58543 1.66671 4.22888 2.22861 3.22868 3.2288C2.22849 4.229 1.66659 5.58555 1.66659 7.00004C1.66659 8.41453 2.22849 9.77108 3.22868 10.7713C4.22888 11.7715 5.58543 12.3334 6.99992 12.3334ZM6.33525 9.66671L3.50659 6.83804L4.44925 5.89537L6.33525 7.78137L10.1059 4.01004L11.0493 4.95271L6.33525 9.66671Z"
                fill="#0010F7"
            />
        </svg>
    );

    const listItem = "hp-d-flex-center hp-mt-8";
    const listText = "hp-d-block hp-ml-8 hp-caption hp-font-weight-400 hp-text-color-dark-0";

    const listMap = itemValues.map((item, index) => (
        <Col
            key={index}
            md={8}
            span={24}
        >
            <div className="hp-landing-pricing-item hp-p-24 hp-border-radius hp-bg-black-10 hp-bg-dark-100">
                <div>
                    <Row justify="space-between">
                        <Col lg={item.best ? 15 : 24} md={item.best ? 10 : 24} span={item.best ? 15 : 24}>
                            <h5 className="hp-mb-0">{item.title}</h5>
                            <p className="hp-caption hp-mb-sm-8 hp-text-color-black-60">
                                {item.subTitle}
                            </p>
                        </Col>

                        {item.best && (
                            <Col>
                                <span className="hp-caption hp-border-radius hp-py-4 hp-px-16 hp-bg-color-primary-4 hp-text-color-primary-1">
                                    Best Price
                                </span>
                            </Col>
                        )}
                    </Row>

                    <span className="h1 hp-d-block hp-mt-sm-8 hp-mt-18">{item.price} <h3 className="hp-text-color-primary-1">{item.credits}</h3></span>

                    <p className="hp-caption hp-mt-sm-0 hp-mt-4 hp-mb-0 hp-text-color-black-60">
                        {item.billed}
                    </p>

                    <ul className="hp-mt-24 hp-mb-0 hp-p-0">
                        {Object.entries(item.list).map(([key, value], index) => (
                            <li key={index} className={listItem}>
                                {listSVG}
                                <span className={listText}>{value} <Link to={`/services/${key.replace(".", "")}`}>{key}</Link></span>
                            </li>
                        ))}
                    </ul>
                </div>

                
                <Button href={`${token != 'null' ? '/pricing/' + item.link : '/authentication/login'}`}   className="hp-mt-64" block ghost type="primary">
                    {item.button}
                </Button>
            </div>
        </Col>
    ));

    return (


                <Row>
                    <Col span={24} className="hp-text-center">
                        <h2 className="h1 hp-mb-4">Pricing & Plans</h2>

                        <p className="hp-p1-body hp-mt-sm-0 hp-mt-32 hp-mb-0">
                            Paying more?! Getting more!
                        </p>
                    </Col>

                    <Col span={24}>
                        <Row
                            align="middle"
                            justify="center"
                            className="hp-mt-64 hp-mb-32"
                        >
                            <span
                                className={`hp-caption ${billedValue
                                    ? "hp-text-color-black-100 hp-text-color-dark-0"
                                    : "hp-text-color-primary-1"
                                    }`}
                            >
                                Simple pricing
                            </span>

                            <Switch
                                className="hp-mx-8"

                                onChange={onChange}
                            />

                            <span
                                className={`hp-caption ${billedValue
                                    ? "hp-text-color-primary-1"
                                    : "hp-text-color-black-100 hp-text-color-dark-0"
                                    }`}
                            >
                                Advanced pricing
                            </span>
                        </Row>

                        <Row gutter={[32, 24]}>
                            {listMap}
                        </Row>
                    </Col>


                </Row>


    )
}
