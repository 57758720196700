import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Spin,
  Col,
  Divider,
  Button,

} from "antd";

export default function InfoProfile({balance, activated}) {
  const [token, setToken] = useState(localStorage.getItem("beetUserToken"));
  const [activatedloaded, setActivatedLoaded] = useState(false);
  const [balanceloaded, setBalanceLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";
  
  useEffect(() => {
    if (balance !== undefined && balance !== null) {
      setBalanceLoaded(true);
    }
  }, [balance]);

  useEffect(() => {
    if (activated !== undefined && activated !== null) {
      setActivatedLoaded(true);
    }
  }, [activated]);
  
  useEffect(() => {
    if (isDisabled) {
      const startTime = Date.now();
      const timeoutId = setTimeout(() => {
        setIsDisabled(false);
        setRemainingTime(0);
      }, 20000);
      const intervalId = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const remaining = 20000 - elapsed;
        if (remaining <= 0) {
          clearInterval(intervalId);
          setRemainingTime(0);
        } else {
          setRemainingTime(Math.ceil(remaining / 1000));
        }
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
        clearInterval(intervalId);
      };
    }
  }, [isDisabled]);

  const handleClick = async () => {
    setIsDisabled(true);
    const reactivate = async (email) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email }),
      };
  
      const response = await fetch(`${apiUrl}/api/reactivate`, requestOptions);
      const data = await response.json();
}
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`${apiUrl}/api/users/me`, requestOptions);
    const data = await response.json();

    reactivate(data.email)
  };


  return (
    <div>
      <Col md={24} span={24}>
        <h1>Account information</h1>
        <p className="hp-p1-body hp-mb-0">
          Account details
        </p>
      </Col>

      <Divider className={dividerClass} />
      <Col md={24} span={24}>
        <h3>Activation status</h3>
        <div className="hp-p1-body hp-mb-0">
          { activatedloaded ? (activated ? <p className="hp-text-color-primary-3">Activated</p> : <><p className="hp-text-color-danger-3">Not activated</p>
        
        {isDisabled ? <p className="hp-p1-body hp-text-color-success-1 hp-text-color-dark-0 hp-font-weight-500 hp-mb-0">Link was sent to your email</p> : <p className="hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-font-weight-500 hp-mb-0">To activate your account, you need to click activation link in the mail we sent</p>}
        {isDisabled ? <><Button type="primary" style={{ width: "auto", marginTop: "1em" }} disabled>Resend e-mail</Button> <p style={{ marginTop: "0.5em" }}>Button will be re-enabled in {remainingTime} seconds</p></>: <Button type="primary" className="hp-btn-outline hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" style={{ width: "auto", marginTop: "1em" }} onClick={handleClick}>Resend e-mail</Button>}</>)
        :
        (<Spin size="large" />)}
        </div>
      </Col>

      <Divider className={dividerClass} />

      <Col md={24} span={24}>
        <h4>Balance</h4>
        { balanceloaded ?
        (<>
        <p className="hp-p1-body hp-mb-0">
          Your balance is <b className="hp-text-color-danger-1"> {balance.toFixed(2)} {balance === 1 ? "Credit" : "Credits"} </b>
        </p>
        <Link to="/pricing"><Button type="primary" className="hp-btn-outline hp-text-color-secondary-1 hp-border-color-secondary-1 hp-hover-bg-secondary-1" style={{ width: "auto", marginTop: "1em" }} >Top-up balance</Button></Link> </>)
        : (<Spin size="large" />) }
      </Col>
      <Divider className={dividerClass} />

    </div>
  );
}
