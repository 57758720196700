import React from "react";


import { Card, Row, Col, Progress, Button, Menu, Dropdown } from "antd";
import { RiMoneyEuroCircleLine, RiFolderMusicLine, RiDownloadFill, RiArrowDropDownLine } from "react-icons/ri";


const mediaMetadata = {

  // required
  title: 'Pure Water',

  // optional
  artist: 'Meydän',

  // optional
  album: 'Interplanetary Forest',

  // optional
  artwork: [

      // src, sizes and type is required
      {src: '/path/to/image/64px/64px', sizes: '64x64', type: 'image/png'},
      {src: '/path/to/image/128px/128px', sizes: '128x128', type: 'image/png'},
  ],
};


export default function CreditMasterCard(props) {
  const { src, src1, src2, src3, src4, name } = props;
  const menu = (
  <Menu >
    <Menu.Item key="1" >
      <a href={src} download>Text (docx)</a>
    </Menu.Item>

    <Menu.Item key="2">
      <a href={src1} download>Subs (docx)</a>
    </Menu.Item>

    <Menu.Item key="3">
      <a href={src2} download>Subs (srt)</a>
    </Menu.Item>
    <Menu.Item key="4">
      <a href={src3} download>Text (txt)</a>
    </Menu.Item>
  </Menu>
);
  return (
    <Card className="hp-border-color-black-40 hp-card-3">
      <Row>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h5 className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">Processed file(s)</h5>
              <Row align="middle">
                <h5 style={{ wordBreak: "break-word", overflowWrap: "break-word" }} className="hp-mb-0 hp-ml-4">{name}</h5>
              </Row>
            </Col>

            
          </Row>



        <Row justify='center' align='middle' className="hp-mt-24">
          <Col>
            {src && src1 && src2 && src3 ? (
              <Dropdown overlay={menu}>
                <Button style={{width:100}} type="primary" shape="round" ghost>
                  Download
                </Button>
              </Dropdown>
            ) : null}
            {src4 ? (
              <Button
                type="primary" shape="round"
                href={src4}
                ghost
                icon={<RiDownloadFill className="remix-icon" />}
              >
                Download
              </Button>
            ) : null}
          </Col>
        </Row>

        </Col>
      </Row>
    </Card>
  );
}
