import React, { useState, useEffect, useContext } from "react";

import { Radio, Space, Select, Spin, Input, Form } from "antd";

import { Card, Row, Col, Upload, message, Progress, Empty, Button, Alert } from "antd";

import * as mmb from 'music-metadata-browser';
import { getInfo } from 'react-mediainfo'
import CreditMasterCard from "./creditMasterCard";
import OrdersFeatureCard from "./ordersFeatureCard";
import PageContent from "../../../layout/components/content/page-content";
import { UserContext } from "../../../context/UserContext";
import {
  RiUploadCloud2Line,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiCoinsLine,
} from "react-icons/ri";
const { Dragger } = Upload;;


export default function Neuneureco({balance, onUpdatedBalance, activated}) {

  const [allData, setAllData] = useState([]);
  const [codeClass, setCodeClass] = useState(false);
  const [radioSwitch, setradioSwitch] = useState('txt');
  const [languageSwitch, setlanguageSwitch] = useState('en');
  const [token] = useContext(UserContext);
  const [finduration, setFinduration] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'uk');
  const [alertErrorVisible, setAlertErrorVisible] = useState(false);
  const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [form] = Form.useForm();
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  const [activatedLoaded, setActivatedLoaded] = useState(false);
  const [displayCount, setDisplayCount] = useState(3);
  const [inputType, setInputType] = useState('file');
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiYou = process.env.REACT_APP_YOUTUBE_API;
  const loadMore = () => {
    setDisplayCount(displayCount + 3);
  };

  
  
  function toggleChecked() {
    setTimeout(() => setCodeClass(!codeClass), 100);
    setCheckedCode(!checkedCode);
  }
  const onRadioChange = (e) => {
    setradioSwitch(e.target.value)

  };
  const onLanguageChange = value => {
    setLanguage(value);
    localStorage.setItem('language', value);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);
  const getLeads = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/voskw`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      const data = await response.json();
      setAllData(data);
      setLoaded(true);
    }
  };

  const getRedisValue = async (filename, service) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    };
    const response = await fetch(`${apiUrl}/getRedisValue?filename=${filename}&service=${service}`, requestOptions);
    if (!response.ok) {
        setErrorMessage("Something went wrong. Couldn't get the Redis value");
    } else {
        const data = await response.json();
        if (data.value === "True") {
            // If the Redis value is "True", reload the leads
            getLeads();
        } else {
            // If the Redis value is not "True", re-trigger this function after 5 seconds
            setTimeout(() => getRedisValue(filename, service), 5000);
        }
    }
};
  const checkDuration = async (duration) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/duration/${duration}_voskw`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      const data = await response.json();
      if (data == true) {return true}
      else {return false}
    }
  };
  const withdrawBalance = async (duration) => {
 
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/withdraw/${duration}_voskw`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      
      const data = await response.json();
      if (data == true) {return true;}
      else {return false}
    }
  };

  function validateYoutubeURL(url) {
    const regExp = /^(https?:\/\/)?((m|www)\.)?(youtube\.com\/watch\?.*v=|youtu\.be\/)[^\s&]+/;
    return regExp.test(url);
}



  function convertDurationToSeconds(duration) {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
  
    const hours = (parseInt(match[1]) || 0);
    const minutes = (parseInt(match[2]) || 0);
    const seconds = (parseInt(match[3]) || 0);
  
    return hours * 3600 + minutes * 60 + seconds;
  }

  async function getVideoDetails(youtubeUrl) {
    // Validate the YouTube URL first
    if (!validateYoutubeURL(youtubeUrl)) {
      console.error(`Invalid YouTube URL: ${youtubeUrl}`);
      return null;
    }
  
    // Extract video id from URL
    let video_id = extractVideoID(youtubeUrl);
    if (!video_id) {
      console.error(`Could not extract video id from YouTube URL: ${youtubeUrl}`);
      return null;
    }
  
    // Use YouTube API to get video details
    const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${video_id}&key=${apiYou}&part=snippet`);
    const data = await response.json();
  
    if (data.items.length === 0) {
      // Invalid video id
      return null;
    }
    const videoDetails = data.items[0];
    const duration = videoDetails.contentDetails.duration;
    const youtubeTitle = videoDetails.snippet.title;
    return {
      duration,
      youtubeTitle
    };
  }
  




  function extractVideoID(url) {
    let video_id;
    if (url.includes("youtu.be")) {
      video_id = url.split("youtu.be/")[1];
      const paramsStart = video_id.indexOf('?');
      if (paramsStart !== -1) {
        video_id = video_id.substring(0, paramsStart);
      }
    } else if (url.includes("youtube.com")) {
      const urlParams = new URLSearchParams(new URL(url).search);
      video_id = urlParams.get('v');
    }
    return video_id;
  }

  useEffect(() => {
    getLeads();
  }, []);
  useEffect(() => {
    if (balance !== undefined && balance !== null) {
      setBalanceLoaded(true);
    }
  }, [balance]);

  useEffect(() => {
    if (activated !== undefined && activated !== null && activated === true) {
      setActivatedLoaded(true);
    }
    else {setActivatedLoaded(false);}
  }, [activated]);

  async function handleFormSubmit(values) {
    const { youtubeURL } = values;
    // Use YouTube URL validator
    const isValidYoutubeURL = validateYoutubeURL(youtubeURL);
    if (!isValidYoutubeURL) {
      message.error('Invalid YouTube URL');
      return;
    }
  
    const videoDetails = await getVideoDetails(youtubeURL);
    if (videoDetails === null) {
      message.error('Could not fetch video details');
      return;
    }

    const { duration, youtubeTitle } = videoDetails;
  
    const durationInSeconds = convertDurationToSeconds(duration);

    if (durationInSeconds > 7200) {
      message.error('The video is too big to process. Video limit for now is 120 minutes');
      return;}

    const dur = Math.ceil(durationInSeconds/60).toString();
    const isDurationAllowed = await checkDuration(dur);
    if (isDurationAllowed) {
      const response = await fetch(`${apiUrl}/voskw/${language}_${radioSwitch}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ youtubeURL: youtubeURL, youtubeTitle: youtubeTitle, duration: dur }),
      });
    
      // Check response
      if (!response.ok) {
        message.error('Failed to send request');
        return;
      }
      
      // If everything goes well
      message.success('YouTube link is processing. Wait for it!');
      const responseData = await response.json();
      withdrawBalance(dur);
      onUpdatedBalance();
      setTimeout(() => {
        getLeads();
      }, 2000);
      form.resetFields();
      setInputType('file')
    } else {message.error('You have no enough credits');
    return;}
  }


  const props = {
    name: "file",
    multiple: true,

    action: `${apiUrl}/voskw/${language}_${radioSwitch}`,
    beforeUpload: async (info) => {
      // Check for file size
      if (info.size > 2000000000) {
        setAlertErrorVisible(true)
        setErrorAlert(`${info.name} File is too big. File need to be less then 2GB`);
        setTimeout(() => {
          setAlertErrorVisible(false);
        }, 10000);  // 10 seconds
        return false;
      }
    
      const isAudio = info.type.includes("audio/");
      const isVideo = info.type.includes("video/");
    
      // Check if the file is audio or video
      if (!isAudio && !isVideo) {
        setAlertErrorVisible(true)
        setErrorAlert(`${info.name} file upload failed. File have wrong format`);
        setTimeout(() => {
          setAlertErrorVisible(false);
        }, 10000);  // 10 seconds
        return false;
      }
    
      let dur;
    
      if (isAudio) {
        const metadata = await mmb.parseBlob(info);
        dur = Math.ceil(metadata.format.duration / 60).toString();
      } else if (isVideo) {
        const one = await getInfo(info);
        dur = Math.ceil(one.media.track[1].Duration / 60).toString();
        console.log(dur)
      }
    
      // Check if duration is allowed
      const isDurationAllowed = await checkDuration(dur);
      if (isDurationAllowed) {
        setFinduration(dur);
        return true;
      } else {
        setAlertErrorVisible(true)
        setErrorAlert(`${info.name} file upload failed. You have no enough credits`);
        setTimeout(() => {
          setAlertErrorVisible(false);
        }, 10000);  // 10 seconds
        return false;
      }
    },
    maxCount: 1,
    data: { duration: finduration },
    headers: {
      authorization: "Bearer " + token,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        setAlertSuccessVisible(true)
        setSuccessAlert(`${info.file.name} file uploaded successfully.`);
        setTimeout(() => {
          setAlertSuccessVisible(false);
        }, 10000);  // 10 seconds

        withdrawBalance(finduration);
        onUpdatedBalance();
        setTimeout(() => {
          getLeads();
        }, 2000);
      } else if (status === "error") {
        setAlertErrorVisible(true)
        setErrorAlert(`${info.file.name} file upload failed.`);
        setTimeout(() => {
          setAlertErrorVisible(false);
        }, 10000);  // 10 seconds
      }

    },
    onDrop() {},
  };
    return (
    <>
    <Row gutter={[32, 32]} className="hp-mb-32">
        <Col span={24}>
        <PageContent
          title="NeuNeu.reco"
          desc="NeuNeu.reco is STT (Speach to text) recognition service."
          backcolor="#7a0464"
        />
      </Col>
      <Col align="middle"  span={24}>
        <Col xl={12} md={12} sm={24} span={24}>
        {balanceLoaded ? (
            <OrdersFeatureCard minutes={Math.round(balance*10)} price="($0.10 per min)"/>) : (
              <Spin size="large" />
            )}
          </Col>
            
      </Col>
      <Col align="middle" md={24} sm={24} xs={24}>
        <h2  className="hp-mb-8">Choose your input</h2>

        <p className="hp-p1-body">
          Choose from local file upload or YouTube link
        </p>
      </Col>
      <Col align="middle" md={24} sm={24}>
    <Radio.Group onChange={(e) => setInputType(e.target.value)} defaultValue="file" buttonStyle="solid">
      <Radio.Button value="file">File Upload</Radio.Button>
      <Radio.Button value="text">YouTube link</Radio.Button>
    </Radio.Group>
    </Col>
      <Col align="middle" md={24} sm={24} xs={24}>
        <h2  className="hp-mb-8">Choose language</h2>

        <p className="hp-p1-body">
          Choose the desired language of text recognition
        </p>
      </Col>
    <Col align="middle" md={24} sm={24} xs={24}>
    <Select onChange={onLanguageChange} value={language}>
            <Option value="en">English</Option>
            <Option value="uk">Ukrainian</Option>
            <Option value="pl">Polish</Option>
            <Option value="tr">Turkish</Option>
            <Option value="ru">Russian</Option>
            <Option value="de">German</Option>
            <Option value="it">Italian</Option>
            <Option value="es">Spanish</Option>
            <Option value="fr">French</Option>
            <Option value="lt">Lithuanian</Option>
            <Option value="pt">Portuguese</Option>
            <Option value="yi">Yiddish</Option>
            <Option value="ja">Japanese</Option>
            <Option value="ar">Arabic</Option>
            <Option value="ko">Korean</Option>
            <Option value="kk">Kazakh</Option>    
          </Select>


    </Col>
      <Col align="middle" md={24} sm={24} xs={24}>
      <Card className="hp-border-color-black-40">
      <Row>
        <Col className="hp-mb-16" span={24}>
        {inputType === 'file' &&
        <Col className="hp-mb-16" span={24}>
          <Row>
            <Col lg={24} span={24}>
              <h4>Upload file</h4>
              <p className="hp-p1-body">
              You can upload any sound file, that contain audio in it. This file will be processed immidiately after the upload. You will get source file and processed file under the next tab on the page.
              </p>
            </Col>

            
          </Row>
        </Col>}

        
        {inputType === 'file' && (activatedLoaded ? (<Col span={24} className="hp-upload-dragger-col">
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <RiUploadCloud2Line className="remix-icon" />
            </p>

            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>

          </Dragger> </Col>) : (<Col span={24} className="hp-upload-dragger-col"><Dragger disabled {...props}>
            <p className="ant-upload-drag-icon">
              <RiUploadCloud2Line className="remix-icon" />
            </p>

            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger> </Col>))}
        {inputType === 'text' && 
        <Col className="hp-mb-16" span={24}>
        <Form layout="vertical" form={form} onFinish={handleFormSubmit}>

        <h4>YouTube Video URL</h4>
          
          <p className="hp-p1-body">
          Paste the link to your chosen YouTube video (up to a 120-minute duration) and let the magic begin!.
              </p>
          <Form.Item label='' name="youtubeURL">
            <Input placeholder="Enter YouTube URL" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Process
            </Button>
          </Form.Item>
        </Form></Col>}
          {alertErrorVisible && 
          <Alert
      message="Error"
      description={errorAlert}
      type="error"
    />}
    {alertSuccessVisible && 
          <Alert
      message="Success"
      description={successAlert}
      type="success"
    />}
        </Col>
      </Row>


    </Card>
      </Col>
      
    <Col align="middle" md={24} sm={24} xs={24}>
        <h2 className="hp-mb-8">Your files</h2>

      </Col>
      {allData.length == 0 ? (
    <>
    <Col span={24}>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Col>
    </>
):( <>
    {allData.slice(0, displayCount).map((files) => {
        let s3out;
        console.log(s3out)
        let src, src1, src2, src3, src4;
        try {
        // Try to parse the JSON
        s3out = JSON.parse(files.s3out);
        // If JSON is parsed successfully, assign the properties
        src = s3out.file1;
        src1 = s3out.file2;
        src2 = s3out.file3;
        src3 = s3out.file4;
        } catch (e) {
        // If an error occurs, use files.s3out as is
        src4 = files.s3out;
        }
        
        const name = files.file;
        const service = files.service; // I'm assuming here you have a service property in files
        if (files.s3out === "0") {
            // Here's where you call your getRedisValue function
            getRedisValue(name, service);
        }
        return (
        <>
        {files.s3out === "0" ? <>
            <Col span={24}> <h5 style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>{name}</h5>
            <p className="hp-mb-0 hp-p1-body">File is processing</p> <Spin size="large" />
            <Progress showInfo={false} percent={100} status="active" />  </Col></>
        :
        <Col md={24} span={24}>
        <CreditMasterCard name={name} src={src} src1={src1} src2={src2} src3={src3} src4={src4}/>
        </Col>} 
        </>
        )
    })}
    {displayCount < allData.length && (
        <Col align="middle" span={24}>
        <Button block onClick={loadMore}>Load More</Button>
        </Col>
    )}
</>
)}
      
  </Row>
  </>
  )}