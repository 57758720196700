// src/Analytics.js
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

const GA_TRACKING_ID = 'G-0XHS6JMPDE'; // Replace with your Google Analytics tracking ID

const withAnalytics = (WrappedComponent) => {
    ReactGA.initialize(GA_TRACKING_ID);
  
    const trackPage = (page) => {
      ReactGA.set({ page });
      ReactGA.event({
        category: 'page_view',
        action: 'view_item_list',
        label: page,
      });
    };
  
    return (props) => {
      const history = useHistory();
      useEffect(() => {
        const unlisten = history.listen((location) => {
          trackPage(location.pathname + location.search);
        });
        trackPage(history.location.pathname + history.location.search);
        return () => {
          unlisten();
        };
      }, [history]);
  
      return <WrappedComponent {...props} />;
    };
  };

export default withAnalytics;
