import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Form, Input, Button } from "antd";

import LeftContent from "../leftContent";
import Footer from "../footer";

export default function RecoverPassword() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessage2, setErrorMessage2] = useState(null);
  const [sentEmail, setSentEmail] = useState(null);
  const password = process.env.REACT_APP_PASSWORD;
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    if (errorMessage === false || errorMessage === true) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
    
  }, [errorMessage]);

  const submitLogin = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };

    const response = await fetch(`${apiUrl}/api/pass_recover`, requestOptions);
    const data = await response.json();
    if (data == false) {
      setErrorMessage(true)
      setErrorMessage2("Please enter valid email")
    } else {
      setSentEmail(true)
    }
  };
  const handleSubmit = (e) => {
    if (email) {submitLogin()}
    else {setErrorMessage2("Please enter valid email");setErrorMessage(true)}
  };
  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={11}
            xl={15}
            lg={20}
            md={20}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            {!sentEmail ? <><h1 className="hp-mb-sm-0">Recover Password</h1>
            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
              We’ll e-mail you instructions on how to reset your password.
            </p>

            <Form align="middle"
              layout="vertical"
              name="basic"
              className="hp-mt-sm-16 hp-mt-32"
              onFinish={handleSubmit}
            >
              <Form.Item label="E-mail :" value={email}>
                <Input id="validating" placeholder="you@example.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
              </Form.Item>
              { errorMessage == true && <p  align="middle" style={{ color: "red" }}>{errorMessage2}</p> }
              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button block type="primary" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
            <div className="hp-form-info hp-text-center">
              <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                Go back to
              </span>

              <Link
                to="/authentication/login"
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
              >
                Login
              </Link>
            </div></> : <><h1 align="middle" className="hp-mb-sm-0">Email was sent</h1>
            <p align="middle" className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
             Check your email for reset password link.
            </p></>}

            

            <Footer />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
