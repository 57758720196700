import { Award, Archive, Wallet, VideoAdd, LampCharge, Home2 } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [

    {
        id: "home",
        title: <IntlMessages id="sidebar-pages-home" />,
        icon: <Home2 size={18} />,
        navLink: "/",
    },
    {
        id: "services",
        title: <IntlMessages id="sidebar-pages-services" />,
        icon: <VideoAdd size={18} />,
        children: [
            {
                id: "Neuneuvoice",
                title: "NeuNeu.voice",
                navLink: "/services/neuneuvoice",
            },
            {
                id: "Neuneunoise",
                title: "NeuNeu.noise",
                navLink: "/services/neuneunoise",
            },
            {
                id: "Neuneumusic",
                title: "NeuNeu.music",
                navLink: "/services/neuneumusic",
            },
            {
                id: "Neuneureco",
                title: "NeuNeu.reco",
                navLink: "/services/neuneureco",
            },
            // {
            //     id: "Neuneukey",
            //     title: "NeuNeu.key",
            //     navLink: "/services/neuneukey",
            // },
            // {
            //     id: "Neuneucut",
            //     title: "NeuNeu.cut",
            //     navLink: "/services/neuneucut",
            // },
            {
                id: "Neuneutext",
                title: "NeuNeu.text",
                navLink: "/services/neuneutext",
            },
        ],
    },
    {
        id: "examples",
        title: <IntlMessages id="sidebar-pages-examples" />,
        icon: <LampCharge size={18} />,
        navLink: "/examples/neuneuvoice",
    },
    {
        id: "pricing",
        title: <IntlMessages id="sidebar-pages-pricing" />,
        icon: <Wallet size={18} />,
        navLink: "/pricing",
    },
    {
        id: "help",
        title: <IntlMessages id="sidebar-pages-help" />,
        icon: <Archive size={18} />,
        navLink: "/help",
    },
    {
        id: "about",
        title: <IntlMessages id="sidebar-pages-about" />,
        icon: <Award size={18} />,
        navLink: "/about",
    },
    
    
];

export default pages