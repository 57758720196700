import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import { Col, Avatar, Badge, Menu } from "antd";
import {
  User,
  Notification,
  Activity,
  Setting,
  Password,
  Heart,
} from "react-iconly";

import menuImg from "../../assets/images/pages/profile/menu-img.svg";
import avatar from "../../assets/images/memoji/memoji-1.png";

export default function MenuProfile(props) {
  const [email, setEmail] = useState()
  const menuIconClass = "remix-icon hp-mr-8";
  function menuFooterItem() {
    if (props.footer !== "none") {
      return (
        <div className="hp-profile-menu-footer">
          <img src={menuImg} alt="Profile Image" />
        </div>
      );
    }
  }

  

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  // Redux
  const customise = useSelector(state => state.customise)

  return (
    <Col flex="240px" className="hp-profile-menu hp-py-24">
      <div className="hp-w-100">
        <div className="hp-profile-menu-header hp-mt-md-16 hp-text-center">
         

          <Badge>
            <Avatar size={80} src={avatar} />
          </Badge>

          <p className="hp-mt-24 hp-mb-4 hp-p1-body"><b>{props.email}</b></p>
        </div>

        <Menu
          mode="inline"
          className="hp-w-100 hp-profile-menu-body"
          theme={customise.theme == "light" ? "light" : "dark"}
        >
          <Menu.Item
            key="1"
            icon={<User set="curved" className={menuIconClass} />}
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === "personal-information"
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"}
            `}
            onClick={props.onCloseDrawer}
          >
            <Link to="/profile/personal-information">
              Account Info
            </Link>
          </Menu.Item>







          <Menu.Item
            key="5"
            icon={<Password set="curved" className={menuIconClass} />}
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === "password-change"
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"}
            `}
            onClick={props.onCloseDrawer}
          >
            <Link to="/profile/password-change">Password Change</Link>
          </Menu.Item>

        </Menu>
      </div>

      {menuFooterItem()}
    </Col>
  );
}
