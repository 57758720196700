import React from "react";
import { Form, Input, Button } from "antd";

function PromoCodeForm({ onCreate, form }) {
  const onFinish = async (values) => {
    await onCreate(values);
    form.resetFields();
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: "Please input the promo code!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Sum"
        name="sum"
        rules={[{ required: true, message: "Please input the sum!" }]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add Promo Code
        </Button>
      </Form.Item>
    </Form>
  );
}

export default PromoCodeForm;
