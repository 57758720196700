import React, { useState, useEffect } from "react";

import { Row, Col } from "antd";

var ord_id = new Date().getTime();

export default function Fondy10(price) {
  const [ErrorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("beetUserToken");
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleCreateOrder = async (e) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        order_id: ord_id,
      }),
    };
    const response = await fetch(`${apiUrl}/api/payment`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong when creating order");
    }
  };

  useEffect(() => {
    const cssLink = document.createElement("link");
    cssLink.href = "https://pay.fondy.eu/latest/checkout-vue/checkout.css";
    cssLink.rel = "stylesheet";
    document.head.appendChild(cssLink);

    const script = document.createElement("script");
    script.src = "https://pay.fondy.eu/latest/checkout-vue/checkout.js";
    script.type = "text/javascript";
    script.onload = () => {
      initFondy();
    };
    document.body.appendChild(script);

    return () => {
      document.head.removeChild(cssLink);
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    handleCreateOrder();
    initFondy();
  }, [price]);

  const initFondy = () => {
    var Options = {
      options: {
        methods: ["card"],
        methods_disabled: [],
        full_screen: false,
        active_tab: "card",
        theme: {
          type: "dark",
          preset: "silver",
        },
      },
      params: {
        merchant_id: 1513990,
        currency: "USD",
        lang: "en",
        order_id: ord_id,
        amount: Number(price.price),
        order_desc: `${Number(price.price) / 100} Credits`,
      },
    };
    if (typeof fondy !== "undefined") {
      fondy("#checkout-container", Options);
    }
  };
  return (
    <section className="hp-landing-pricing hp-py-sm-64 hp-pt-96 hp-pb-120">
      <div className="hp-landing-container">
        <Row>
          <Col span={24}>
            <div id="checkout-container"></div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
