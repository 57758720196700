import React from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Card } from "antd";

import Callcenter from "../../assets/images/pages/knowledgebase/contact.png";
import Charts from "../../assets/images/pages/knowledgebase/analyse.png";
import Logistic from "../../assets/images/pages/knowledgebase/location.png";
import Mailing from "../../assets/images/pages/knowledgebase/newsletter.png";
import Finance from "../../assets/images/pages/knowledgebase/save-money.png";
import Seo from "../../assets/images/pages/knowledgebase/seo.png";

export default function KnowledgeBaseCards() {
  return (
    <Row gutter={[32, 32]} className="hp-mb-32">
      <Col xl={8} md={12} xs={24}>
        <Link to={'/about/about'}>
        <Card className="hp-text-center">
          <img src={Callcenter} alt="Call Center" />
          <h4>About</h4>

        </Card>
        </Link>
      </Col>

      <Col xl={8} md={12} xs={24}>
      <Link to={'/about/mediakit'}>
        <Card className="hp-text-center">
          <img src={Logistic} alt="Shipping & Logistics" />
          <h4>Media Kit</h4>

        </Card>
        </Link>
      </Col>

      <Col xl={8} md={12} xs={24}>
      <Link to={'/about/terms'}>
        <Card className="hp-text-center">
          <img src={Finance} alt="Finance" />
          <h4>Terms of Service</h4>

        </Card>
        </Link>
      </Col>

      <Col xl={8} md={12} xs={24}>
      <Link to={'/about/private'}>
        <Card className="hp-text-center">
          <img src={Charts} alt="Charts" />
          <h4>Privacy Policy</h4>

        </Card>
        </Link>
      </Col>

      <Col xl={8} md={12} xs={24}>
      <Link to={'/about/public'}>
        <Card className="hp-text-center">
          <img src={Mailing} alt="Mailings" />
          <h4>Public Offer</h4>

        </Card>
        </Link>
      </Col>


    </Row>
  );
}