import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router';

import { Row, Col } from "antd";

import Exampleses from './detail/Detail';

export default function Ecommerce(id) {
  const cart = useSelector(state => state.ecommerce.cart)
  const current = useSelector(state => state.ecommerce.currentItem)

  // Checkout Price
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  useEffect(() => {
    let items = 0;
    let price = 0;

    cart.forEach((item) => {
      items += item.qty;
      price += (item.qty * (item.discount ? item.discount : item.price))
    });

    setTotalItem(items);
    setTotalPrice(price);
  }, [cart, totalItem, totalPrice, setTotalItem, setTotalPrice]);

  return (
    <Row gutter={32} className="hp-ecommerce-app hp-mb-32">
      <Col span={24}>
        <Switch>
         
            <Route path={`/examples/${id.id}`}>
              <Exampleses id = {id.id} />
            </Route>
         
        </Switch>
      </Col>
    </Row >
  )
}