import React, { useState, useEffect, useContext } from "react";


import * as mmb from 'music-metadata-browser';
import { Card, Row, Col, Upload, message, Progress, Empty, Spin, Button } from "antd";


import CreditMasterCard from "./creditMasterCard";
import OrdersFeatureCard from "./ordersFeatureCard";
import PageContent from "../../../layout/components/content/page-content";
import { UserContext } from "../../../context/UserContext";
import {
  RiUploadCloud2Line,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
} from "react-icons/ri";

const { Dragger } = Upload;

export default function Neuneuvoice({balance, onUpdatedBalance, activated}) {

  const [allData, setAllData] = useState([]);
  const [codeClass, setCodeClass] = useState(false);
  const [token] = useContext(UserContext);
  const [finduration, setFinduration] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  const [activatedLoaded, setActivatedLoaded] = useState(false);
  const [displayCount, setDisplayCount] = useState(3);
  const apiUrl = process.env.REACT_APP_API_URL;
  const loadMore = () => {
    setDisplayCount(displayCount + 3);
  };
  onUpdatedBalance()
  function toggleChecked() {
    setTimeout(() => setCodeClass(!codeClass), 100);
    setCheckedCode(!checkedCode);
  }
  useEffect(() => {
    onUpdatedBalance();
  }, []);
  const getLeads = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/voicefx`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      const data = await response.json();
      setAllData(data);
      setLoaded(true);
    }
  };

  const getRedisValue = async (filename, service) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    };
    const response = await fetch(`${apiUrl}/getRedisValue?filename=${filename}&service=${service}`, requestOptions);
    if (!response.ok) {
        setErrorMessage("Something went wrong. Couldn't get the Redis value");
    } else {
        const data = await response.json();
        if (data.value === "True") {
            // If the Redis value is "True", reload the leads
            getLeads();
        } else {
            // If the Redis value is not "True", re-trigger this function after 5 seconds
            setTimeout(() => getRedisValue(filename, service), 5000);
        }
    }
};
  const checkDuration = async (duration) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/duration/${duration}_voicefx`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      const data = await response.json();
      
      if (data == true) {return true}
      else {return false}
    }
  };
  const withdrawBalance = async (duration) => {
 
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/withdraw/${duration}_voicefx`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      
      const data = await response.json();
      if (data == true) {return true;}
      else {return false}
    }
  };

  useEffect(() => {
    getLeads();
  }, []);
  useEffect(() => {
    if (balance !== undefined && balance !== null) {
      setBalanceLoaded(true);
    }
  }, [balance]);

  useEffect(() => {
    if (activated !== undefined && activated !== null && activated === true) {

      setActivatedLoaded(true);
    }
    else {setActivatedLoaded(false);}
  }, [activated]);

  

  const props = {
    name: "file",

    multiple: false,
    beforeUpload: async (info) => {
    { if (info.size > 60000000) 
      {message.error({
      content: `${info.name} File is too big. File need to be less then 60Mb`,
      icon: <RiCloseCircleLine className="remix-icon" />,
    }); 
    return false 
      }
      if (!info.type.includes("audio/")) 
      {message.error({
        content: `${info.name} file upload failed. File have wrong format`,
        icon: <RiCloseCircleLine className="remix-icon" />,
      });return false;}
      const metadata = await mmb.parseBlob(info);
    const dur = Math.ceil(metadata.format.duration/60).toString();
    const isDurationAllowed = await checkDuration(dur);
    if (isDurationAllowed) {

      setFinduration(dur);
      return true;
    } else {message.error({
          content: `${info.name} file upload failed. You have no enough credits`,
          icon: <RiCloseCircleLine className="remix-icon" />,
        });

        return false }
        
      }

    }, 
    maxCount: 1,
    data: { duration: finduration },
    headers: {
      authorization: "Bearer " + token,
    },

    action: `${apiUrl}/voicefx`,
    onChange(info) {

      const { status } = info.file;
      if (status !== "uploading") { 
      }
      if (status === "done") {
        message.success({
          content: `${info.file.name} file uploaded successfully.`,
          icon: <RiCheckboxCircleLine className="remix-icon" />,
        });
        withdrawBalance(finduration);
        onUpdatedBalance();
        setTimeout(() => {
          getLeads();
        }, 2000);
      } else if (status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          icon: <RiCloseCircleLine className="remix-icon" />,
        });
      }
     if (info.file.response === 'Wrong file type') {
        message.error({
          content: `${info.file.name} file upload failed.`,
          icon: <RiCloseCircleLine className="remix-icon" />,
        });
      }
    },
    onDrop() {},
  };
    return (
    <>
    <Row  gutter={[32, 32]} className="hp-mb-32">
        <Col span={24}>
        <PageContent
          title="NeuNeu.voice"
          desc="Imrove quality of voice significaly"
          backcolor="#fada32"
        />
      </Col>
      <Col align="middle"  span={24}>
        <Col xl={12} md={12} sm={24} span={24}>
        {balanceLoaded ? (
            <OrdersFeatureCard minutes={Math.round(balance*10)} price="($0.10 per min)"/>) : (
              <Spin size="large" />
            )}
          </Col>
            
      </Col>
      <Col align="middle" md={24} sm={24}>
      <Card className="hp-border-color-black-40">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row>
            <Col lg={24} span={24}>
              <h4>Upload file</h4>
              <p className="hp-p1-body">
              You can upload any sound file, that contain audio in it. This file will be processed immidiately after the upload. You will get source file and processed file under the next tab on the page.
              </p>
            </Col>

            
          </Row>
        </Col>

        <Col span={24} className="hp-upload-dragger-col">
        {activatedLoaded ? ( 
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <RiUploadCloud2Line className="remix-icon" />
            </p>

            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>

            
          </Dragger> ) : (<Dragger disabled {...props}>
            <p className="ant-upload-drag-icon">
              <RiUploadCloud2Line className="remix-icon" />
            </p>

            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>

            
          </Dragger> )}
        </Col>
      </Row>


    </Card>
      </Col>
    <Col align="middle" md={24} sm={24}>
        <h2 className="hp-mb-8">Your files</h2>

      </Col>
      {allData.length == 0 ? (
        <>
        <Col md={24} span={24}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
        </>
      ):( <>
        {allData.slice(0, displayCount).map((files) =>
          { 
            const s3out = files.s3out
            const name_ = files.file
            const name = files.file
            const service = files.service; // I'm assuming here you have a service property in files
            if (files.s3out === "0") {
            // Here's where you call your getRedisValue function
            getRedisValue(name, service);
        }
            return (
              <>
          {s3out === "0" ? <>
          <Col span={24}> <h5 style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>{name}</h5>
    <p className="hp-mb-0 hp-p1-body">File is processing</p> <Spin size="large" />
    <Progress showInfo={false} percent={100} status="active" />  </Col></>
          :
          <Col md={24} span={24}>
          <CreditMasterCard src={s3out} name={name_} />
        </Col>}   
        
        </>
        )
      })}
      {displayCount < allData.length && (
          <Col align="middle" span={24}>
            <Button block onClick={loadMore}>Load More</Button>
          </Col>
        )}
      </>
      )}
      
      
      
  </Row>
  </>
  )}