import React from "react";
import {AudioPlayerControlSprite, Audio} from 'react-audio-player-pro';
import reactAudioPlayerProStyle from 'react-audio-player-pro/dist/style.css';
import Plyr from "plyr-react"
import "plyr-react/plyr.css"

import { Card, Row, Col, Progress, Button } from "antd";
import { RiMoneyEuroCircleLine, RiFolderMusicLine, RiDownloadFill } from "react-icons/ri";


const mediaMetadata = {

  // required
  title: 'Pure Water',

  // optional
  artist: 'Meydän',

  // optional
  album: 'Interplanetary Forest',

  // optional
  artwork: [

      // src, sizes and type is required
      {src: '/path/to/image/64px/64px', sizes: '64x64', type: 'image/png'},
      {src: '/path/to/image/128px/128px', sizes: '128x128', type: 'image/png'},
  ],
};

export default function CreditMasterCard(props) {
  const { src, name } = props;
  return (
    <Card className="hp-border-color-black-40 hp-card-3">
      <Row>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h5 className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">Processed file(s)</h5>
              <Row align="middle">
                <h5 style={{ wordBreak: "break-word", overflowWrap: "break-word" }} className="hp-mb-0 hp-ml-4">{name}</h5>
              </Row>
            </Col>

            <RiFolderMusicLine size={50} className="hp-text-color-primary-1" />
          </Row>

          <Row className="hp-mt-48">
          <AudioPlayerControlSprite/>
            <Audio src={src} preload="auto" duration={100}/>
          </Row>


        </Col>
      </Row>
    </Card>
  );
}
