import React, { useState, useEffect, useContext } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";


import { UserContext } from "../../context/UserContext";
import { Table, Row, Col, Card, Button } from "antd";


export default function servicesTable() {
  const [token] = useContext(UserContext);
  const [datadb, setDatadb] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchUser = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`${apiUrl}/api/services/all`, requestOptions);
    const fetchedData = await response.json();

    setDatadb(fetchedData);

    
  };
  useEffect(() => {
    fetchUser();
  }, []);
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Balance',
      dataIndex: 'billing',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() + 3);
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(date);
    
        const formattedTime = new Intl.DateTimeFormat('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        }).format(date);
    
        return `${formattedDate} at ${formattedTime}`;
      },
    },
    {
      title: "Processed",
      dataIndex: "active",
      render: (value) => (value === '1' ? <CheckOutlined /> : <CloseOutlined />),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    // console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <Card className="hp-border-color-black-40">
      <Row>
        <Col className="hp-mb-16" span={21}>
          <h4>Services</h4>
        </Col>



        <Col span={24}>
          <Table columns={columns} dataSource={datadb} onChange={onChange} scroll={{ x: 500 }} />
        </Col>
      </Row>


    </Card>
  );
}
