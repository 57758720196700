import React from 'react'
import { Route, Switch } from "react-router-dom";

import LandingPricing from './pricing';
import Fondy10 from './fondy10';


export default function Landing() {
    return (

        <>


            <Switch>
              <Route path="/pricing" exact>
              <LandingPricing />
              </Route>

              <Route path="/pricing/5">
              <Fondy10 price='500' />
              </Route>

              <Route path="/pricing/100">
              <Fondy10 price='10000' />
              </Route>

              <Route path="/pricing/20">
              <Fondy10 price='2000' />
              </Route>

              <Route path="/pricing/200">
              <Fondy10 price='20000' />
              </Route>

              <Route path="/pricing/50">
                <Fondy10 price='5000' />
              </Route>
              <Route path="/pricing/500">
                <Fondy10 price='50000' />
              </Route>
            </Switch>

            

            </>
    )
}
