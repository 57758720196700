import { Suspense, useEffect, useState, useContext } from "react";


// Redux
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../redux/customise/customiseActions";

// Router
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";

// Layouts
import VerticalLayout from "../layout/VerticalLayout";

// Components
import Home from "../view/home";
import About from "../view/knowledge-base-1"
import Aboutout from "../view/knowledge-base-1/about"
import Neuneuvoice from "../view/services/neuneuvoice";
// import Neuneukey from "../view/services/neuneukey";
import Neuneutext from "../view/services/neuneutext";
import Neuneumusic from "../view/services/neuneumusic";
import Neuneunoise from "../view/services/neuneunoise";
import Neuneureco from "../view/services/neuneureco";
// import Neuneucut from "../view/services/neuneucut";
import FAQ from "../view/faq";
import Forgot from "../view/forgot"
import Landing from "../view/landing";
import Ecommerce from "../view/examples"
import Profile from "../view/profile";
import Login from "../view/authentication/login";
import Adminpanel from "../view/adminpanel";
import Register from "../view/authentication/register";
import Reset from "../view/authentication/reset-password";
import Recover from "../view/authentication/recover-password";
import Error404 from "../view/pages/errors/404";
import Activation from "../view/activation";
import Redeem from "../view/redeem"
import { UserContext } from "../context/UserContext";




export default function Router() {

    const [token] = useContext(UserContext);
    // Redux
    const customise = useSelector(state => state.customise)
    const dispatch = useDispatch()
    const [balance, setBalance] = useState()
    const [activated, setActivated] = useState()
    const [email, setEmail] = useState()
    const [fetchedData, setFetchedData] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    // Dark Mode
    useEffect(() => {
        document.querySelector("body").classList.add(customise.theme)
        dispatch(theme(customise.theme))
    }, [])
    let themeLocal

    useEffect(() => {
        if (localStorage) {
            themeLocal = localStorage.getItem("theme")
        }

        if (themeLocal === "light" || themeLocal === "dark") {
            document.querySelector("body").classList.add(themeLocal)
            dispatch(theme(themeLocal))
        } else {
            document.querySelector("body").classList.add(customise.theme)
            dispatch(theme(customise.theme))
        }
    }, [])

    
    const fetchUser = async () => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
  
        const response = await fetch(`${apiUrl}/api/users/me`, requestOptions);
        const data = await response.json();
        setBalance(parseFloat(data.billing))
        setActivated(data.activated)
        setEmail(data.email)

        
      };
    useEffect(() => {
        fetchUser();
      }, []);

    const renderWithVerticalLayout = (Component) => (
      <VerticalLayout balance={balance} activated={activated}>
        <Component />
      </VerticalLayout>
    );

    const renderWithVerticalLayoutForStarter = (Component) => (
      <VerticalLayout balance={balance} activated={activated} username={email}>
        <Component />
      </VerticalLayout>
    );

    const [loggedIn, setLoggedIn] = useState(true);


    return (
      <BrowserRouter>
        {token ? (
          <Switch>
            {/* Home Page */}
            {email === "alx.sel@gmail.com" ? (
              <Route
                exact
                path="/adminpanel/:id"
                render={() => renderWithVerticalLayout(() => <Adminpanel />)}
              />
            ) : (
              <Route
                path="/adminpanel"
                render={() => renderWithVerticalLayout(() => <Home />)}
              />
            )}
                <Route exact path={'/'} render={() => renderWithVerticalLayoutForStarter(() => <Home />)}/>
                <Route path={'/pricing/:id'} render={() => renderWithVerticalLayout(() => <Landing />)}/>
                <Route path={'/profile/:id'} render={() => renderWithVerticalLayout(() => <Profile balance={balance} activated={activated} email={email}/>)}/>
                <Route path={'/redeem'} render={() => renderWithVerticalLayout(() => <Redeem />)}/>
                <Route path={'/services/neuneuvoice'} render={() => renderWithVerticalLayout(() => <Neuneuvoice balance={balance} onUpdatedBalance={fetchUser}  activated={activated}/>)}/>
                <Route exact path={'/services/neuneunoise'} render={() => renderWithVerticalLayout(() => <Neuneunoise balance={balance} onUpdatedBalance={fetchUser}  activated={activated}/>)}/>
                <Route exact path={'/services/neuneumusic'} render={() => renderWithVerticalLayout(() => <Neuneumusic balance={balance} onUpdatedBalance={fetchUser}  activated={activated}/>)}/>
                {/* <Route exact path={'/services/neuneucut'} render={() => renderWithVerticalLayout(() => <Neuneucut balance={balance} onUpdatedBalance={fetchUser}  activated={activated}/>)}/> */}
                <Route exact path={'/services/neuneureco'} render={() => renderWithVerticalLayout(() => <Neuneureco balance={balance} onUpdatedBalance={fetchUser}  activated={activated}/>)}/>
                {/* <Route exact path={'/services/neuneukey'} render={() => renderWithVerticalLayout(() => <Neuneukey balance={balance} onUpdatedBalance={fetchUser}  activated={activated}/>)}/> */}
                <Route exact path={'/services/neuneutext'} render={() => renderWithVerticalLayout(() => <Neuneutext balance={balance} onUpdatedBalance={fetchUser}  activated={activated}/>)}/>
                <Route exact path={'/authentication/login'} render={() => renderWithVerticalLayout(() => <Home />)}/>
                <Route exact path={'/authentication/register'} render={() => renderWithVerticalLayout(() => <Home />)}/>
                <Route exact path={'/authentication/recover-password'} render={() => { return ( <Recover />) }}/>
                <Route exact path={'/authentication/reset-password'} render={() => { return ( <Reset />) }}/>
                <Route exact path={'/pricing'} render={() => renderWithVerticalLayout(() => <Landing />)}/>
                <Route exact path={'/about'} render={() => renderWithVerticalLayout(() => <About />)}/>
                <Route exact path={'/about/about'} render={() => renderWithVerticalLayout(() => <Aboutout par="about" />)}/>
                <Route exact path={'/about/mediakit'} render={() => renderWithVerticalLayout(() => <Aboutout par="mediakit" />)}/>
                <Route exact path={'/about/terms'} render={() => renderWithVerticalLayout(() => <Aboutout par="terms" />)}/>
                <Route exact path={'/about/private'} render={() => renderWithVerticalLayout(() => <Aboutout par="private" />)}/>
                <Route exact path={'/about/public'} render={() => renderWithVerticalLayout(() => <Aboutout par="public" />)}/>
                <Route exact path={'/about/affiliate'} render={() => renderWithVerticalLayout(() => <Aboutout par="affiliate" />)}/>
                <Route exact path={'/help'} render={() => renderWithVerticalLayout(() => <FAQ />)}/>
                <Route path={'/activation/:id'} render={() => { return ( <Activation />) }}/>
                <Route path={'/forgot/:id'} render={() => { return ( <Forgot />) }}/>
                <Route path={"/examples/neuneuvoice"} render={() => renderWithVerticalLayout(() => <Ecommerce id = "neuneuvoice" />)}/>
                <Route path={"/examples/neuneunoise"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneunoise' />)}/>
                <Route path={"/examples/neuneumusic"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneumusic' />)}/>
                {/* <Route path={"/examples/neuneucut"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneucut' />)}/> */}
                <Route path={"/examples/neuneureco"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneureco' />)}/>
                {/* <Route path={"/examples/neuneukey"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneukey' />)}/> */}

            <Route
              path="*"
              render={() => <Error404 />} // Catch-all route for authenticated users
            />
          </Switch>
        ) : (
          <Switch>
            <Route
              exact
              path="/"
              render={() => renderWithVerticalLayoutForStarter(() => <Home />)}
            />
                <Route exact path={'/authentication/recover-password'} render={() => { return ( <Recover />) }}/>
                <Route exact path={'/authentication/reset-password'} render={() => { return ( <Reset />) }}/>
                <Route exact path={'/pricing'} render={() => renderWithVerticalLayout(() => <Landing />)}/>
                <Route exact path={'/about'} render={() => renderWithVerticalLayout(() => <About />)}/>
                <Route exact path={'/about/about'} render={() => renderWithVerticalLayout(() => <Aboutout par="about" />)}/>
                <Route exact path={'/about/mediakit'} render={() => renderWithVerticalLayout(() => <Aboutout par="mediakit" />)}/>
                <Route exact path={'/about/terms'} render={() => renderWithVerticalLayout(() => <Aboutout par="terms" />)}/>
                <Route exact path={'/about/private'} render={() => renderWithVerticalLayout(() => <Aboutout par="private" />)}/>
                <Route exact path={'/about/public'} render={() => renderWithVerticalLayout(() => <Aboutout par="public" />)}/>
                <Route exact path={'/about/affiliate'} render={() => renderWithVerticalLayout(() => <Aboutout par="affiliate" />)}/>
                <Route exact path={'/help'} render={() => renderWithVerticalLayout(() => <FAQ />)}/>
                <Route path={'/activation/:id'} render={() => { return ( <Activation />) }}/>
                <Route path={'/forgot/:id'} render={() => { return ( <Forgot />) }}/>
                <Route path={"/examples/neuneuvoice"} render={() => renderWithVerticalLayout(() => <Ecommerce id = "neuneuvoice" />)}/>
                <Route path={"/examples/neuneunoise"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneunoise' />)}/>
                <Route path={"/examples/neuneumusic"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneumusic' />)}/>
                <Route path={"/examples/neuneucut"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneucut' />)}/>
                <Route path={"/examples/neuneureco"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneureco' />)}/>
                <Route path={"/examples/neuneukey"} render={() => renderWithVerticalLayout(() => <Ecommerce id = 'neuneukey' />)}/>
                <Route path={'/pricing/:id'} render={() => { return ( <Login />) }}/>
                <Route path={'/profile/:id'} render={() => { return ( <Login />) }}/>
                <Route exact path={'/authentication/login'} render={() => { return ( <Login />) }}/>
                <Route exact path={'/redeem'} render={() => { return ( <> <Login />  </>) }}/>
                <Route exact path={'/services/neuneuvoice'} render={() => { return ( <> <Login />  </>) }}/>
                <Route exact path={'/services/neuneunoise'} render={() => { return ( <> <Login />  </>) }}/>
                <Route exact path={'/services/neuneumusic'} render={() => { return ( <> <Login />  </>) }}/>
                <Route exact path={'/services/neuneucut'} render={() => { return ( <> <Login />  </>) }}/>
                <Route exact path={'/services/neuneureco'} render={() => { return ( <> <Login />  </>) }}/>
                <Route exact path={'/services/neuneukey'} render={() => { return ( <> <Login />  </>) }}/>
                <Route exact path={'/services/neuneutext'} render={() => { return ( <> <Login />  </>) }}/>
                <Route exact path={'/authentication/register'} render={() => { return ( <Register />) }}/>
                

            <Route
              path="*"
              render={() => <Error404 />} // Catch-all route for non-authenticated users
            />
          </Switch>
        )}
      </BrowserRouter>

    );
};