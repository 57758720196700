import { Col } from "antd";

import image from "../../../assets/images/memoji/newspaper.svg";

export default function HeaderText() {
  return (
    <Col
      xl={14}
      lg={14}
      className="hp-header-left-text hp-d-flex-center"
    >
      

      
    </Col>
  );
};