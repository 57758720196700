import React from "react";
import { Link } from "react-router-dom";


import { Button, Col } from "antd";


export default function AdminButton() {

  return (
    <Col className="hp-languages hp-mr-8">
      <div className="hp-d-flex-center">
        
        <Button type="link" className="hp-text-color-danger-1 hp-hover-text-color-danger-3" shape="default"  size='default'><Link to="/adminpanel/users">Cabinet</Link></Button>
        
      </div>
    </Col>
  );
};