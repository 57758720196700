
import React, { useState, useEffect, useContext } from "react";

import { Card, Row, Col, Upload, message, Empty, Progress, Radio, Space, Spin, Button, Input, Form } from "antd";

import * as mmb from 'music-metadata-browser';

import CreditMasterCard from "./creditMasterCard";
import OrdersFeatureCard from "./ordersFeatureCard";
import PageContent from "../../../layout/components/content/page-content";
import { UserContext } from "../../../context/UserContext";
import {
  RiUploadCloud2Line,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
} from "react-icons/ri";

const { Dragger } = Upload;
const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";

export default function Neuneumusic({balance, onUpdatedBalance, activated}) {
  const [allData, setAllData] = useState([]);
  const [codeClass, setCodeClass] = useState(false);
  const [radioSwitch, setradioSwitch] = useState('mp3');
  const [channelSwitch, setchannelSwitch] = useState('2');
  const [token] = useContext(UserContext);
  const [finduration, setFinduration] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  const [activatedLoaded, setActivatedLoaded] = useState(false);
  const [displayCount, setDisplayCount] = useState(3);
  const [form] = Form.useForm();
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiYou = process.env.REACT_APP_YOUTUBE_API;
  const loadMore = () => {
    setDisplayCount(displayCount + 3);
  };
  const [inputType, setInputType] = useState('file');
  
  function toggleChecked() {
    setTimeout(() => setCodeClass(!codeClass), 100);
    setCheckedCode(!checkedCode);
  }
  const onRadioChange = (e) => {

    setradioSwitch(e.target.value)

  };
  const onChannelChange = (e) => {

    setchannelSwitch(e.target.value)

  };

  useEffect(() => {
    if (balance !== undefined && balance !== null) {
      setBalanceLoaded(true);
    }
  }, [balance]);

  useEffect(() => {
    if (activated !== undefined && activated !== null && activated === true) {

      setActivatedLoaded(true);
    }
    else {setActivatedLoaded(false);}
  }, [activated]);

  const checkDuration = async (duration) => {
 
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/duration/${duration}_demu`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong.");
    } else {
      
      const data = await response.json();
      if (data == true) {return true;}
      else {return false}
    }
  };

  const withdrawBalance = async (duration) => {
 
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/withdraw/${duration}_demu`, requestOptions);
    if (!response.ok) {

      setErrorMessage("Something went wrong.");
    } else {

      const data = await response.json();
      if (data == true) {;return true;}
      else {return false}
    }
  };
  function validateYoutubeURL(url) {
    const regExp = /^(https?:\/\/)?((m|www)\.)?(youtube\.com\/watch\?.*v=|youtu\.be\/)[^\s&]+/;
    return regExp.test(url);
}



  function convertDurationToSeconds(duration) {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
  
    const hours = (parseInt(match[1]) || 0);
    const minutes = (parseInt(match[2]) || 0);
    const seconds = (parseInt(match[3]) || 0);
  
    return hours * 3600 + minutes * 60 + seconds;
  }

  async function getVideoDetails(youtubeUrl) {
    // Validate the YouTube URL first
    if (!validateYoutubeURL(youtubeUrl)) {
      console.error(`Invalid YouTube URL: ${youtubeUrl}`);
      return null;
    }
  
    // Extract video id from URL
    let video_id = extractVideoID(youtubeUrl);
    if (!video_id) {
      console.error(`Could not extract video id from YouTube URL: ${youtubeUrl}`);
      return null;
    }
  
    // Use YouTube API to get video details
    const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${video_id}&key=${apiYou}&part=snippet`);
    const data = await response.json();
  
    if (data.items.length === 0) {
      // Invalid video id
      return null;
    }
    const videoDetails = data.items[0];
    const duration = videoDetails.contentDetails.duration;
    const youtubeTitle = videoDetails.snippet.title;
    return {
      duration,
      youtubeTitle
    };
  }
  




  function extractVideoID(url) {
    let video_id;
    if (url.includes("youtu.be")) {
      video_id = url.split("youtu.be/")[1];
      const paramsStart = video_id.indexOf('?');
      if (paramsStart !== -1) {
        video_id = video_id.substring(0, paramsStart);
      }
    } else if (url.includes("youtube.com")) {
      const urlParams = new URLSearchParams(new URL(url).search);
      video_id = urlParams.get('v');
    }
    return video_id;
  }
  
  const getLeads = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },

    };
    const response = await fetch(`${apiUrl}/demu`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      const data = await response.json();
      setAllData(data);
      setLoaded(true);
    }
  };
  useEffect(() => {
    getLeads();
  }, []);

  const getRedisValue = async (filename, service) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    };
    const response = await fetch(`${apiUrl}/getRedisValue?filename=${filename}&service=${service}`, requestOptions);
    if (!response.ok) {
        setErrorMessage("Something went wrong. Couldn't get the Redis value");
    } else {
        const data = await response.json();
        if (data.value === "True") {
            // If the Redis value is "True", reload the leads
            getLeads();
        } else {
            // If the Redis value is not "True", re-trigger this function after 5 seconds
            setTimeout(() => getRedisValue(filename, service), 5000);
        }
    }
};
  async function handleFormSubmit(values) {
    const { youtubeURL } = values;
    // Use YouTube URL validator
    const isValidYoutubeURL = validateYoutubeURL(youtubeURL);
    if (!isValidYoutubeURL) {
      message.error('Invalid YouTube URL');
      return;
    }
  
    const videoDetails = await getVideoDetails(youtubeURL);
    if (videoDetails === null) {
      message.error('Could not fetch video details');
      return;
    }

    const { duration, youtubeTitle } = videoDetails;
  
    const durationInSeconds = convertDurationToSeconds(duration);

    if (durationInSeconds > 900) {
      message.error('The video is too big to process. Video limit for now is 15 minutes');
      return;}

    const dur = Math.ceil(durationInSeconds/60).toString();
    const isDurationAllowed = await checkDuration(dur);
    if (isDurationAllowed) {
      const response = await fetch(`${apiUrl}/demu/${radioSwitch}_${channelSwitch}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ youtubeURL: youtubeURL, youtubeTitle: youtubeTitle, duration: dur }),
      });
    
      // Check response
      if (!response.ok) {
        message.error('Failed to send request');
        return;
      }
      
      // If everything goes well
      message.success('YouTube link is processing. Wait for it!');
      const responseData = await response.json();
      withdrawBalance(dur);
      onUpdatedBalance();
      setTimeout(() => {
        getLeads();
      }, 2000);
      form.resetFields();
      setInputType('file')
    } else {message.error('You have no enough credits');
    return;}
  }



  const props = {
    name: "file",
    multiple: false,

    action: `${apiUrl}/demu/${radioSwitch}_${channelSwitch}`,
    beforeUpload: async (info) => {
    { if (info.size > 60000000) 
      {message.error({
      content: `${info.name} File is too big. File need to be less then 60Mb`,
      icon: <RiCloseCircleLine className="remix-icon" />,
    }); 
    return false 
      }
      if (!info.type.includes("audio/")) 
      {message.error({
        content: `${info.name} file upload failed. File have wrong format`,
        icon: <RiCloseCircleLine className="remix-icon" />,
      });return false;}
      const metadata = await mmb.parseBlob(info);
      const dur = Math.ceil(metadata.format.duration/60).toString();
      
      const isDurationAllowed = await checkDuration(dur);
      if (isDurationAllowed) {
        
        setFinduration(dur);
        return true;
      } else {message.error({
            content: `${info.name} file upload failed. You have no enough credits`,
            icon: <RiCloseCircleLine className="remix-icon" />,
          });
  
          return false }
          
        }
  
      },  
    maxCount: 1,
    data: { duration: finduration },
    headers: {
      authorization: "Bearer " + token,
    },
    
    onChange(info) {

      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success({
          content: `${info.file.name} file uploaded successfully.`,
          icon: <RiCheckboxCircleLine className="remix-icon" />,
        });
        withdrawBalance(finduration);
        onUpdatedBalance();
        setTimeout(() => {
          getLeads();
        }, 2000);


      } else if (status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          icon: <RiCloseCircleLine className="remix-icon" />,
        });
      }
    },
    onDrop() {},
  };
    return (
    <>     
    <Row gutter={[32, 32]} className="hp-mb-32">
        <Col span={24}>
        <PageContent
          title="NeuNeu.music"
          desc="Separate your track on only voice and only music tracks"
          backcolor="#be2b4e"
        />
        </Col>
        <Col align="middle"  span={24}>
        <Col xl={12} md={12} sm={24} span={24}>
        
        {balanceLoaded ? (
            <OrdersFeatureCard minutes={Math.round(balance*10)} price="($0.10 per min)"/> ) : (
              <Spin size="large" />
            )}
          </Col>
            
      </Col>
      <Col align="middle" md={24} sm={24}>
        <h2  className="hp-mb-8">Choose output</h2>

        <p className="hp-p1-body hp-mb-32">
          You can choose your output files between MP3 and WAV. Also you can choose the way algorythm will split the track - into voice and musice or voice and instruments.
        </p>
      </Col>
      <Col align="middle" md={24} sm={24}>
      <Space direction="vertical">
      <Col align="middle" md={24} sm={24}>
    <Radio.Group onChange={onRadioChange} defaultValue="mp3" buttonStyle="solid">
      <Radio.Button value="mp3">MP3</Radio.Button>
      <Radio.Button value="wav">WAV</Radio.Button>
    </Radio.Group>
    </Col>
    <Col align="middle" md={24} sm={24}>
    <Radio.Group onChange={onChannelChange} defaultValue="2" buttonStyle="solid">
      <Radio.Button value="2">Vocal and Music</Radio.Button>
      <Radio.Button value="4">Vocal and 3 Instruments</Radio.Button>
      <Radio.Button value="6">Vocal and 5 Instruments</Radio.Button>
    </Radio.Group>
    </Col>
    
    
    <Col align="middle" md={24} sm={24}>
    <Radio.Group onChange={(e) => setInputType(e.target.value)} defaultValue="file" buttonStyle="solid">
      <Radio.Button value="file">File Upload</Radio.Button>
      <Radio.Button value="text">YouTube link</Radio.Button>
    </Radio.Group>
    </Col>
    </Space>
    </Col>
    
    <Col align="middle" md={24} sm={24}>
    <Card align="middle" className="hp-border-color-black-40">
      <Row>
      {inputType === 'file' &&
        <Col className="hp-mb-16" span={24}>
          <Row>
            <Col lg={24} span={24}>
              <h4>Upload file</h4>
              <p className="hp-p1-body">
              You can upload any sound file, that contain audio in it. This file will be processed immidiately after the upload. You will get source file and processed file under the next tab on the page.
              </p>
            </Col>

            
          </Row>
        </Col>}

        
        {inputType === 'file' && (activatedLoaded ? (<Col span={24} className="hp-upload-dragger-col">
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <RiUploadCloud2Line className="remix-icon" />
            </p>

            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>

          </Dragger> </Col>) : (<Col span={24} className="hp-upload-dragger-col"><Dragger disabled {...props}>
            <p className="ant-upload-drag-icon">
              <RiUploadCloud2Line className="remix-icon" />
            </p>

            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger> </Col>))}
        {inputType === 'text' && 
        <Col className="hp-mb-16" span={24}>
        <Form layout="vertical" form={form} onFinish={handleFormSubmit}>

        <h4>YouTube Video URL</h4>
          
          <p className="hp-p1-body">
          Paste the link to your chosen YouTube video (up to a 15-minute duration) and let the magic begin!.
              </p>
          <Form.Item label='' name="youtubeURL">
            <Input placeholder="Enter YouTube URL" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Process
            </Button>
          </Form.Item>
        </Form></Col>}

        
      </Row>


    </Card>
      </Col>
      
    <Col align="middle" md={24} sm={24}>
        <h2 className="hp-mb-8">Your files</h2>

      </Col>
      {allData.length == 0 ? (
        <>
        <Col md={12} span={24}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
        <Col md={12} span={24}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
        </>
      ):( <>
        {allData.slice(0, displayCount).map((files) =>
          { 
            const name = files.file
            const s3out = JSON.parse(files.s3out)
            const src = s3out.file1
            const src2 = s3out.file2
            const src3 = s3out.file3
            const src4 = s3out.file4
            const src5 = s3out.file5
            const src6 = s3out.file6
            const service = files.service; // I'm assuming here you have a service property in files
            if (files.s3out === "0") {
            // Here's where you call your getRedisValue function
            getRedisValue(name, service);
        }
            return (
              <>
              {s3out === 0 ? <>
     <Col span={24}> <h5 style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>{name}</h5>
    <p className="hp-mb-0 hp-p1-body">File is processing</p> <Spin size="large" />
    <Progress showInfo={false} percent={100} status="active" />  </Col></>
:
        <Col md={24} span={24}>
          <CreditMasterCard name={name} src={src} src2={src2} src3={src3} src4={src4} src5={src5} src6={src6}/>
        </Col>}
        </>
        )
      })}
        {displayCount < allData.length && (
          <Col align="middle" span={24}>
          <Button block onClick={loadMore}>Load More</Button>
        </Col>
        )}
      </>
      )}
      
  </Row>
  </>
  )}