import React, { Suspense } from "react";

import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from './redux/store'

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider } from "./context/UserContext";
import App from "./App";
import withAnalytics from './Analytics';
const AppWithAnalytics = withAnalytics(App);

ReactDOM.render(
  <Suspense fallback="loading">
    <UserProvider>
    <GoogleOAuthProvider clientId="882478420488-4pn07lqdr16lbnrb973c8ieuoul7g70t.apps.googleusercontent.com">
    <Provider store={store}>
      <BrowserRouter >
        <AppWithAnalytics />
      </BrowserRouter>
    </Provider>
    </GoogleOAuthProvider>
    </UserProvider>
  </Suspense>,
  document.getElementById("root")
);