import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Divider, Form, Input, Button } from "antd";
import { UserContext } from "../../context/UserContext";


export default function Redeem() {
  const [token] = useContext(UserContext);
  const [redeemcode, setRedeemCode] = useState("");
  const [redeemedCode, setRedeemedCode] = useState(null)
  const [errorMessage, setErrorMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";
  const getLeads = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ promo: redeemcode }),

    };
    const response = await fetch(`${apiUrl}/redeem`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the leads");
    } else {
      const data = await response.json();
      setRedeemedCode(data)
    }
  };
  useEffect(() => {
    if (redeemedCode === false || redeemedCode === true) {
      setTimeout(() => {
        setRedeemedCode(null);
      }, 3000);
    }
    
  }, [redeemedCode]);
  return (
    <Row className="hp-h-100" align="middle" justify="center">
      <Col align="middle" md={24} sm={24} span={24}>
        <h2>Redeem your code here</h2>
        <p className="hp-p1-body hp-mb-0">
          Enter your code into the field.
         </p>

        <Divider className={dividerClass} />
      </Col>

      <Col align="middle" xxl={15} xl={15} md={15} span={24}>
        <Form onFinish={getLeads} layout="vertical" name="basic">
          <Form.Item>
            <Input placeholder="Redeem code" value={redeemcode}
              onChange={(e) => setRedeemCode(e.target.value)} />
          </Form.Item>
          { redeemedCode == false && <p style={{ color: "red" }}>Wrong promo code (or code has been already used)</p> }
          { redeemedCode == true && <p style={{ color: "green" }}>Code is redeemed successfully</p> }
          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Redeem
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}