import {AudioPlayerControlSprite, Audio} from 'react-audio-player-pro';
import "plyr-react/plyr.css"

import { Card, Row, Col, Divider } from "antd";
import { RiFolderMusicLine } from "react-icons/ri";


export default function CreditMasterCard(props) {
  const { src, src2, src3, src4, src5, src6, name } = props;
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";
  return (
    <Card className="hp-border-color-black-40 hp-card-3">
      
      <Row>
        <Col span={24}>
        { name && (
        <>
          <Row justify="space-between">
            <Col>
              <h5 className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">Processed file(s)</h5>
              <Row align="middle">
                <h5 style={{ wordBreak: "break-word", overflowWrap: "break-word" }} className="hp-mb-0 hp-ml-4">{name}</h5>
              </Row>
            </Col>

            <RiFolderMusicLine size={50} className="hp-text-color-primary-1" />
          </Row>
          </>)
        }
          
          <Row className="hp-mt-48">
          { src ? (
            <>
            <h4>Vocal</h4>
          <AudioPlayerControlSprite/>
            <Audio src={src} key={src} controls duration={100}/>
            <Divider className={dividerClass} />
            </>) :(<></>)
        }
        { src2 ? (
            <>          
            {src3 ? <h4>Bass</h4> : <h4>Music</h4> }
          <AudioPlayerControlSprite/>
            <Audio src={src2} key={src2}controls duration={100}/>
            <Divider className={dividerClass} />
            </>) :(<></>)
        }
        { src3 ? (
            <>
            <h4>Drums</h4>
          <AudioPlayerControlSprite/>
            <Audio src={src3} key={src3} controls duration={100}/>
            <Divider className={dividerClass} />
            </>) :(<></>)
        }
        {src4 ? (
            <>
              {src6 ? <h4>Guitar</h4> : <h4>Others</h4> }
              <AudioPlayerControlSprite />
              <Audio src={src4} key={src4} controls duration={100} />
              <Divider className={dividerClass} />
            </>
          ) :(<></>)}
        { src5 ? (
            <>
            <h4>Piano</h4>
          <AudioPlayerControlSprite/>
            <Audio src={src5} key={src5} controls duration={100}/>
            <Divider className={dividerClass} />
            </>) : (<></>)
        }
        { src6 ? (
            <>
            <h4>Others</h4>
          <AudioPlayerControlSprite/>
            <Audio src={src6} key={src6} controls duration={100}/>
            <Divider className={dividerClass} />
            </>) :(<></>)
        }
          </Row>
          
        </Col>
      </Row>
    </Card>
  );
}
