import React from "react";

import { Row, Col, Input } from "antd";

import KnowledgeBaseCards from "./cards";

const { Search } = Input;

export default function About() {
  return (
    <Row gutter={[32, 32]}>

      <Col span={24}>
        <h1 className="hp-mb-0">Hello! </h1>

        <h5 className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
        Here you can learn info about us!
        </h5>
      </Col>




      <Col span={24}>
        <KnowledgeBaseCards />
      </Col>
    </Row>
  );
}