import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Row, Col, Divider, Spin } from "antd";

export default function Activation() {
  const [linkok, setLinkok] = useState();
  const location = useLocation()
  const link = location.pathname.replace('/activation/','')
  const dividerClass = "hp-border-color-black-40 hp-border-color-dark-80";
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/activation/${link}`)
      .then((response) => response.json())
      .then((data) => {
        setLinkok(data)
        window.location.href = '/';
      });
  }, []);

  return (
    <>
    
    
    <Row className="hp-h-100" align="middle" justify="center" style={{marginTop:"30px"}}>
    {typeof linkok === "undefined" ? (
  <Spin size="large" />
) : (
  <Col align="middle" md={24} sm={24} span={24}>
    {linkok === true ? (
      <>
        <h2>Your account is activated</h2>
        <p className="hp-p1-body hp-mb-0">
          You will be redirected to the homepage
        </p>
      </>
    ) : (
      <>
        <h2>Your account is not activated</h2>
        <h4 style={{color:"red"}}>Your link is wrong</h4>
        <p className="hp-p1-body hp-mb-0">
          You will be redirected to the homepage
        </p>
      </>
    )}
    <Divider className={dividerClass} />
  </Col>
)}
    </Row>
    </>
  );
};